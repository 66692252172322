import axios from "utils/axiosConfig";
import { urlService } from "infra/envs/";

class CandidateService {
    constructor() {
        this.dataBaseURL = urlService.CANDIDATES_API_PATH;
        this.cvBaseURL = urlService.CANDIDATE_CV_PATH;
    }

    getNameById(id, arr) {
        const result = arr.find((item) => item.id === id);
        if (result) {
            return result.name;
        }
    }

    // Mapping function to convert API data to the front-end data model
    mapCandidateData(candidate) {
        return {
            id: candidate?.id,
            firstName: candidate?.personal_information?.first_name || null,
            lastName: candidate?.personal_information?.last_name || null,
            headline: candidate?.personal_information?.headline || "-",
            military: {
                obligation:
                    candidate?.personal_information?.military?.obligation,
                deferment:
                    candidate?.personal_information?.military?.deferment_date,
            },
            email: candidate?.contact_information?.email || null,
            phoneCode:
                {
                    id:
                        candidate?.contact_information?.calling_code?.id ||
                        null,
                    name:
                        candidate?.contact_information?.calling_code?.country
                            ?.name &&
                        candidate?.contact_information?.calling_code
                            ?.calling_code
                            ? `${candidate?.contact_information?.calling_code?.country?.name} + ${candidate?.contact_information?.calling_code?.calling_code}`
                            : null,
                    calling_code:
                        candidate?.contact_information?.calling_code
                            ?.calling_code || null,
                } || null,
            phone: candidate?.contact_information?.phone_number || null,
            linkedin: candidate?.contact_information?.linkedin_profile || null,
            collegelink:
                candidate?.contact_information?.collegelink_profile_id || null,
            country: candidate?.personal_information?.city?.country || null,
            city: candidate?.personal_information?.city || null,
            birthdate: candidate?.personal_information?.date_of_birth || null,
            totalYearsOfExperience:
                candidate?.experienceSummary?.totalYearsOfExperience || null,
            summary: {
                currentJobTitle:
                    candidate?.experienceSummary?.currently?.jobTitle || null,
                currentCompanyName:
                    candidate?.experienceSummary?.currently?.company?.name ||
                    null,
                currentCompanyUrl:
                    candidate?.experienceSummary?.currently?.company
                        ?.websiteUrl || null,
                currentRoleExperience:
                    candidate?.experienceSummary?.currently
                        ?.yearsOfExperience || null,
                experienceHighlights:
                    candidate?.experienceSummary?.highlights || null,
                salary: candidate?.job_related_information
                    ?.min_monthly_net_salary,
                noticePeriod:
                    candidate?.personal_information?.notice_period || null,
                motivations: candidate?.personality?.motivations || null,
                preferredPrimarySectors:
                    candidate?.preferences?.sectors?.primary_sectors || null,
                workModel: candidate?.preferences?.working_models || null,
                contactTypes: candidate?.preferences?.means_of_contact || null,
            },
            personalDetailsAndPreferences: {
                compensationPreferences: {
                    netMontlySalary:
                        candidate?.job_related_information
                            ?.min_monthly_net_salary || null,
                    grossAnnualSalary:
                        candidate?.job_related_information
                            ?.min_annual_gross_salary || null,
                    compensationPreferences:
                        candidate?.preferences?.compensation_preferences ||
                        null,

                    noticePeriod:
                        candidate?.personal_information?.notice_period || null,
                    availability:
                        candidate?.personal_information?.availability || null,
                },
                personality: {
                    attitude: candidate?.personality?.attitude,
                    motivation: candidate?.personality?.motivations,
                    softSkills: candidate?.personality?.soft_skills || null,
                    otherSkills: candidate?.preferences?.skills || null,
                },
                contactPreferences: {
                    contactFrequency:
                        candidate?.preferences?.contact_frequency || null,
                    meansOfContact:
                        candidate?.preferences?.means_of_contact || null,
                },
                lookingFor: {
                    workModel: candidate?.preferences?.working_models || null,
                    jobType: candidate?.preferences?.job_types || null,
                    industries: candidate?.preferences?.industries || null,
                    jobFunctions: candidate?.preferences?.job_functions || null,
                    occupations: candidate?.preferences?.occupations || null,
                    preferredSpecificSectors:
                        candidate?.preferences?.sectors?.specific_sectors,
                    preferredSkills: candidate?.preferences?.skills,
                    otherSkills: candidate?.personality?.soft_skills || null,
                    mobility: candidate?.preferences?.mobilities || null,
                    companySize: candidate?.preferences?.company_sizes || null,
                    teamSize: candidate?.preferences?.team_size || null,
                    clientFacing: candidate?.preferences?.client_facing || null,
                },
                personalInformation: {
                    gender: candidate?.personal_information?.gender || null,
                    vehicles: candidate?.personal_information?.vehicles || null,
                    languages: candidate?.preferences?.languages || null,
                    militaryObligation:
                        candidate?.personal_information?.military?.obligation ||
                        null,
                    militaryDeferment:
                        candidate?.personal_information?.military
                            ?.deferment_date || null,
                },
            },
            experiences: [],
            cv: {
                hasCv: candidate?.experience?.has_cv || null,
                cvLastUpdateDate: null,
                cvUrl: candidate?.experience?.has_cv
                    ? `${this.cvBaseURL}${candidate?.id}`
                    : null,
            },
            notes: candidate?.notes || null,
        };
    }

    // Fetch a single candidate by id
    async fetchCandidateById(id) {
        try {
            const response = await axios.get(`${this.dataBaseURL}/${id}`);
            const candidate = this.mapCandidateData(response.data);
            return candidate;
        } catch (error) {
            console.error("Error fetching candidate:", error);
            const candidate = this.mapCandidateData(null);
            return candidate;
        }
    }
}

const service = new CandidateService();
export default service;
