import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DialogBox from "components/lib-ui/DialogBox";
import BlankState from "../BlankState";
import EducationCard from "./../EducationCard";
import EducationForm from "features/candidate/components/candidate-form/EducationForm";

export const Education = () => {
    const currentCandidateEducations = useSelector(
        (state) => state.educations.data
    );

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { loading } = useSelector((state) => state.educations);

    return (
        <Box>
            {/* If educations are not available yet, return a loading
            spinner */}
            {loading && <CircularProgress />}
            {!loading && currentCandidateEducations?.length === 0 && (
                <BlankState
                    text="This candidate has no education data yet!"
                    showButton={true}
                    buttonText="Add education"
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                />
            )}
            {!loading && currentCandidateEducations?.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        gap: 3,
                        flexDirection: "row",
                        whiteSpace: "pre-line",
                    }}
                >
                    {currentCandidateEducations?.map((educationData, index) => (
                        <EducationCard
                            key={index}
                            educationData={educationData}
                        />
                    ))}

                    <Box
                        sx={{
                            position: "fixed",
                            right: "30px",
                            bottom: "30px",
                        }}
                    >
                        {!isDialogOpen && (
                            <Button
                                sx={{
                                    borderRadius: "50px",
                                    padding: "10px 30px",
                                }}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    setIsDialogOpen(true);
                                }}
                            >
                                <AddIcon sx={{ marginRight: "5px" }} />
                                Add education
                            </Button>
                        )}
                    </Box>
                </Box>
            )}

            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Add education"
                floatCancel={true}
                showActionButtons={false}
            >
                <EducationForm
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    isEditMode={false}
                />
            </DialogBox>
        </Box>
    );
};

export default Education;
