import { useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import GeneralUtilityService from "services/GeneralUtilityService";
import useNotifications from "components/hooks/useNotifications";

export const TablePagination = ({
    page,
    setPage,
    isLoading,
    paginationData,
}) => {
    const { renderSuccessNotification } = useNotifications();

    const handlePaginationChange = (_e, selectedPage) => {
        setPage(selectedPage);
        // If results per page is less than 20, there
        // is no need to scroll to top.
        if (paginationData.per_page < 20) return;
        GeneralUtilityService.scrollToTop(600);
    };

    useEffect(() => {
        paginationData?.total &&
            renderSuccessNotification(
                `Found ${paginationData?.total} candidates`
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationData?.total]);

    return (
        <Box mt={6}>
            <Stack
                sx={{
                    width: "100%",
                }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Pagination
                    page={page}
                    onChange={handlePaginationChange}
                    count={paginationData?.last_page}
                    boundaryCount={3}
                    siblingCount={3}
                    showFirstButton
                    disabled={isLoading}
                />

                <Box
                    sx={{
                        marginLeft: "auto",
                    }}
                >
                    <Typography variant="subtitle2">
                        {"Total results: "}
                        {paginationData?.total ?? " calculating..."}
                    </Typography>
                </Box>
            </Stack>
        </Box>
    );
};

export default TablePagination;
