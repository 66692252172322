import axios from "utils/axiosConfig";
import { useState } from "react";
import CandidateService from "services/CandidateService";
import useNotifications from "components/hooks/useNotifications";
import { useDispatch } from "react-redux";
import { currentCandidate } from "reducers/currentCandidateSlice";

export const useUpdateCandidateById = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteNoteLoading, setIsDeleteNoteLoading] = useState(false);
    const [isDeleteSuccessfull, setIsDeleteSuccessfull] = useState(false);
    const [isEditNoteSuccessfull, setIsEditNoteSuccessfull] = useState(false);
    const [isEditNoteLoading, setIsEditNoteLoading] = useState(false);
    const [isLanguagesLoading, setIsLanguagesLoading] = useState(false);
    const [isLanguagesSuccessfull, setIsLanguagesSuccessfull] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const updateCandidateById = async (id, updatedData) => {
        setIsLoading(true);
        try {
            const response = await axios.patch(
                `${CandidateService.dataBaseURL}/${id}`,
                updatedData
            );
            const candidate = CandidateService.mapCandidateData(response.data);
            dispatch(currentCandidate(candidate));
            setRequestSuccessful(true);
            renderNotification("Updated successfully");
            setIsLoading(false);
            return candidate;
        } catch (error) {
            setRequestSuccessful(false);
            renderErrorNotification(`Error updating candidate: ${error}`);
            console.error("Error updating candidate:", error);
            setIsLoading(false);
            throw new Error("Error updating candidate");
        }
    };

    const deleteNote = async (candidateId, noteId) => {
        const endpoint = `${CandidateService.dataBaseURL}/${candidateId}/notes/${noteId}`;
        try {
            setIsDeleteNoteLoading(true);
            const response = await axios.delete(endpoint);
            const candidate = CandidateService.mapCandidateData(response.data);
            dispatch(currentCandidate(candidate));
            setIsDeleteNoteLoading(false);
            setIsDeleteSuccessfull(true);
        } catch (error) {
            renderErrorNotification(error.response.data);
            throw new Error("Failed to delete note");
        } finally {
            setIsDeleteNoteLoading(false);
        }
    };

    const editNote = async (endpointParams, updateData) => {
        const { candidateId, noteId } = endpointParams;
        setIsEditNoteLoading(true);
        const endpoint = `${CandidateService.dataBaseURL}/${candidateId}/notes/${noteId}`;
        try {
            const response = await axios.patch(endpoint, updateData);
            const candidate = CandidateService.mapCandidateData(response.data);
            dispatch(currentCandidate(candidate));
            setIsEditNoteLoading(false);
            setIsEditNoteSuccessfull(true);
            renderNotification("Updated successfully");
        } catch (error) {
            renderErrorNotification(error.response.data);
            throw new Error("Failed to edit note");
        } finally {
            setIsDeleteNoteLoading(false);
        }
    };

    const updateLanguages = async (id, updatedData) => {
        setIsLanguagesLoading(true);
        try {
            const response = await axios.post(
                `${CandidateService.dataBaseURL}/${id}/languages`,
                updatedData
            );
            const candidate = CandidateService.mapCandidateData(response.data);
            dispatch(currentCandidate(candidate));
            setIsLanguagesSuccessfull(true);
            renderNotification("Updated successfully");
            setIsLanguagesLoading(false);
            return candidate;
        } catch (error) {
            setIsLanguagesSuccessfull(false);
            renderErrorNotification(`Error updating candidate: ${error}`);
            console.error("Error updating candidate:", error);
            setIsLanguagesLoading(false);
            throw new Error("Error updating candidate");
        }
    };

    return {
        updateCandidateById,
        deleteNote,
        editNote,
        updateLanguages,
        isLanguagesLoading,
        isLanguagesSuccessfull,
        isEditNoteLoading,
        isEditNoteSuccessfull,
        isDeleteNoteLoading,
        isDeleteSuccessfull,
        isLoading,
        requestSuccessful,
    };
};
