import axios from "utils/axiosConfig";
import { useState } from "react";
import { urlService } from "infra/envs/";
import useNotifications from "components/hooks/useNotifications";
import { useDispatch } from "react-redux";
import GeneralUtilityService from "services/GeneralUtilityService";
import ParserService from "services/Parser.service";
import {
    setParserDataStart,
    setParsedData,
    resetParseData,
} from "reducers/parsedProfileDataSlice";
const CV_PARSE_SUBMIT_ENDPOINT = urlService.CV_PARSE_SUBMIT_PATH;
const CV_PARSE_PDF_ENDPOINT = urlService.CV_PARSE_PDF_PATH;
const CV_PARSE_LINKEDIN_ENDPOINT = urlService.CV_PARSE_LINKEDIN_PATH;
const CV_PARSE_SUBMIT_AND_UPDATE_ENDPOINT =
    urlService.CV_PARSE_SUBMIT_AND_UPDATE_PATH;
const CANDIDATES_ENDPOINT = urlService.CANDIDATES_API_PATH;

const transformResponse = (res) => {
    ParserService.addSimilarityToMatchingResources(
        res?.experiences,
        res?.existingUserData?.experiences
    );

    ParserService.addSimilarityToMatchingResources(
        res?.educations,
        res?.existingUserData?.educations
    );
    const curatedExperiences = ParserService.createCuratedStructure(
        res?.existingUserData?.experiences,
        res?.experiences
    );
    const curatedEducations = ParserService.createCuratedStructure(
        res?.existingUserData?.educations,
        res?.educations
    );
    return { ...res, curatedExperiences, curatedEducations };
};

export const useParser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const {
        renderAddCandidateNotification,
        renderUpdateCandidateNotification,
        renderErrorNotification,
    } = useNotifications();
    const dispatch = useDispatch();

    const parseLinkedinURL = async (data) => {
        setIsLoading(true);
        dispatch(setParserDataStart());
        try {
            const response = await axios.post(CV_PARSE_LINKEDIN_ENDPOINT, data);
            const parsedData = response.data;
            const transformedResponse = transformResponse(parsedData);
            const nomarmalizedData =
                ParserService.normalizeData(transformedResponse);

            dispatch(setParsedData(nomarmalizedData));
            setRequestSuccessful(true);
            setIsLoading(false);
            return nomarmalizedData;
        } catch (error) {
            console.log("error");
            const errorMessage = GeneralUtilityService.extractErrorMessage(
                error.response.data
            );
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const parsePDF = async (data) => {
        setIsLoading(true);
        dispatch(setParserDataStart());
        try {
            const response = await axios.post(CV_PARSE_PDF_ENDPOINT, data, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            const parsedData = response.data;
            const transformedResponse = transformResponse(parsedData);

            const nomarmalizedData =
                ParserService.normalizeData(transformedResponse);

            dispatch(setParsedData(nomarmalizedData));
            setRequestSuccessful(true);
            setIsLoading(false);
            return nomarmalizedData;
        } catch (error) {
            console.log("error");
            const errorMessage = GeneralUtilityService.extractErrorMessage(
                error.response.data
            );
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const submitParsedData = async (data) => {
        try {
            setSubmitLoading(true);

            const response = await axios.post(CV_PARSE_SUBMIT_ENDPOINT, data);

            const { candidate_id } = response.data || {};
            renderAddCandidateNotification(candidate_id);
            setRequestSuccessful(true);
            setSubmitLoading(false);
            dispatch(resetParseData());
            return true;
        } catch (error) {
            const errorMessage = GeneralUtilityService.extractErrorMessage(
                error.response.data
            );
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            return false;
        } finally {
            setSubmitLoading(false);
        }
    };

    const parseExistingCandidate = async (id, type) => {
        setIsLoading(true);
        dispatch(setParserDataStart());
        const data = {
            candidate_id: id,
            type: type,
        };
        try {
            const response = await axios.post(
                urlService.EXISTING_CANDIDATE_PARSE_PATH,
                data
            );
            const parsedData = response.data;
            const nomarmalizedData = ParserService.normalizeData(parsedData);

            const transformedResponse = transformResponse(nomarmalizedData);

            if (type === "pdf") {
                dispatch(setParsedData(transformedResponse));
            }
            if (type === "linkedin") {
                dispatch(setParsedData(transformedResponse));
            }
            setRequestSuccessful(true);
            setIsLoading(false);
            return transformedResponse;
        } catch (error) {
            console.log("error");
        }
    };

    const submitAndUpdateParsedData = async (data) => {
        try {
            setSubmitLoading(true);
            console.log("data to submit", data);

            const candidate = data.existingUserData.generalInfo.id;
            const submitAndUpdateURL = `${CANDIDATES_ENDPOINT}/${candidate}${CV_PARSE_SUBMIT_AND_UPDATE_ENDPOINT}`;
            const response = await axios.post(submitAndUpdateURL, data);
            if (response.data) {
                const { candidate_id } = response.data || {};
                renderUpdateCandidateNotification(candidate_id);
                setRequestSuccessful(true);
                setSubmitLoading(false);
                dispatch(resetParseData());
                return true;
            } else {
                setRequestSuccessful(false);
                return false;
            }
        } catch (error) {
            let errorMessage;
            if (error.response) {
                errorMessage = GeneralUtilityService.extractErrorMessage(
                    error.response.data
                );
            } else if (error.request) {
                errorMessage = "No Response Received";
                console.error(errorMessage);
            }
            setRequestSuccessful(false);
            setSubmitLoading(false);
            renderErrorNotification(errorMessage);
            return false;
        } finally {
            setSubmitLoading(false);
        }
    };
    return {
        parseLinkedinURL,
        parsePDF,
        submitParsedData,
        parseExistingCandidate,
        submitAndUpdateParsedData,
        submitLoading,
        isLoading,
        requestSuccessful,
    };
};

export default useParser;
