import axios from "utils/axiosConfig";
import { useState } from "react";
import { urlService } from "infra/envs/";
import useNotifications from "components/hooks/useNotifications";
import { useDispatch } from "react-redux";
import { useCandidate } from "./useCandidate";

import {
    setExperiencesLoadingStart,
    setExperiences,
    addExperience,
    updateExperience,
} from "reducers/experiencesSlice";

const extractErrorMessage = (errorObject) => {
    const keys = Object.keys(errorObject);
    if (keys.length === 0) return null;

    const firstKey = keys[0];
    const firstErrorMessage = errorObject[firstKey];

    // If it's an array, join the messages together with a space.
    const errorMessage = Array.isArray(firstErrorMessage)
        ? firstErrorMessage.join(" ")
        : firstErrorMessage;

    return `${errorMessage}`;
};

export const useExperiences = () => {
    const { setCandidateToState } = useCandidate();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [fetchRequestSuccessful, setFetchRequestSuccessful] = useState(false);
    const [addRequestSuccessful, setAddRequestSuccessful] = useState(false);
    const [editRequestSuccessful, setEditRequestSuccessful] = useState(false);
    const [deleteRequestSuccessful, setDeleteRequestSuccessful] =
        useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const fetchExperiences = async (candidateId) => {
        setIsLoading(true);
        dispatch(setExperiencesLoadingStart());
        try {
            const response = await axios.get(
                `${urlService.CANDIDATES_API_PATH}/${candidateId}/experiences`
            );

            const experiences = response.data;
            dispatch(setExperiences(experiences));
            setFetchRequestSuccessful(true);
            setIsLoading(false);
            return experiences;
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setFetchRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const editExperience = async (endpointParams, updateData) => {
        const { candidateId, experienceId } = endpointParams;
        setIsLoading(true);
        const endpoint = `${urlService.CANDIDATES_API_PATH}/${candidateId}/experiences/${experienceId}`;
        try {
            const response = await axios.patch(endpoint, updateData);
            dispatch(updateExperience(response.data));
            setEditRequestSuccessful(true);
            setIsLoading(false);
            renderNotification(`Experience succesfully changed`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setEditRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsLoading(false);
            setCandidateToState(candidateId);
        }
    };
    const deleteExperience = async (endpointParams) => {
        const { candidateId, experienceId } = endpointParams;
        setIsDeleteLoading(true);
        const endpoint = `${urlService.CANDIDATES_API_PATH}/${candidateId}/experiences/${experienceId}`;
        try {
            const response = await axios.delete(endpoint);

            dispatch(updateExperience(response.data));
            setDeleteRequestSuccessful(true);
            setIsDeleteLoading(false);
            renderNotification(`Experience deleted successfuly`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setDeleteRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsDeleteLoading(false);
            setCandidateToState(candidateId);
        }
    };

    const addNewExperience = async (candidateId, updateData) => {
        setIsLoading(true);
        dispatch(setExperiencesLoadingStart());
        try {
            const response = await axios.post(
                `${urlService.CANDIDATES_API_PATH}/${candidateId}/experiences`,
                updateData
            );

            const experiences = response.data;
            dispatch(addExperience(experiences));
            setAddRequestSuccessful(true);
            setIsLoading(false);
            return experiences;
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setAddRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsLoading(false);
            setCandidateToState(candidateId);
        }
    };

    return {
        fetchExperiences,
        editExperience,
        addNewExperience,
        deleteExperience,
        isLoading,
        isDeleteLoading,
        fetchRequestSuccessful,
        addRequestSuccessful,
        editRequestSuccessful,
        deleteRequestSuccessful,
    };
};
