import { Box } from "@mui/system";
import ContactPreferences from "./personal-details/ContactPreferences";
import Personality from "./personal-details/Personality";
import Compensation from "./personal-details/Compensation";
import LookingFor from "./personal-details/LookingFor";
import Stack from "@mui/material/Stack";
import PersonalInformation from "features/candidate/components/candidate-view/tabs/personal-details/PersonalInformation";
import { useSelector } from "react-redux";

export const PersonalDetails = () => {
    const candidate = useSelector((state) => state.currentCandidate);

    if (!candidate) {
        return <div>Loading...</div>; // You can show a loading indicator or any other placeholder here
    }

    const {
        compensationPreferences,
        contactPreferences,
        lookingFor,
        personalInformation,
        personality,
    } = candidate.personalDetailsAndPreferences;

    return (
        <Box component="section">
            {/* Parent Container */}
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
                sx={{ width: "100%" }}
            >
                {/* Left Column */}
                <Stack gap={3}>
                    <Compensation candidate={compensationPreferences} />
                    <Personality candidate={personality} />
                    <ContactPreferences candidate={contactPreferences} />
                </Stack>

                {/* Right Column */}
                <Stack gap={3}>
                    <LookingFor candidate={lookingFor} />
                    <PersonalInformation candidate={personalInformation} />
                </Stack>
            </Stack>
        </Box>
    );
};

export default PersonalDetails;
