import React, { useEffect } from "react";
import View from "../components/candidate-view/View";
import { useCandidate } from "components/hooks/useCandidate";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export const CandidateView = () => {
    const { id } = useParams();
    const { setCandidateToState, isLoading } = useCandidate();
    const currentCandidate = useSelector((state) => state.currentCandidate);

    useEffect(() => {
        setCandidateToState(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return !isLoading && currentCandidate !== null ? <View candidateId={id} /> : <CircularProgress />;
};

export default CandidateView;
