import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Alert,
    Button,
    Chip,
    InputAdornment,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { format, differenceInYears, parseISO } from "date-fns";
import Form from "components/lib-ui/Form";
import {
    useRecruitmentProcess,
    useSubmitHiredFormData,
} from "components/hooks/useRecruitmentProcess";
import { GlobalAppDataStorageService } from "infra/storage";
import GeneralUtilityService from "services/GeneralUtilityService";

const stackProps = {
    gap: 3,
};

const mapRecruitmentData = (data) => {
    return {
        company: data.external.job.company,
        position: data.external.job,
        recruiter: data.recruiter,
        hiredInfo: data.hiredInfo,
    };
};

export const NewHireDataForm = ({
    recruitmentProcessData,
    setIsDialogOpen,
}) => {
    const [isAlertVisible, setIsAlertVisible] = useState(true);
    const candidate = useSelector((state) => state.currentCandidate);
    const { submitHiredFormData, isLoading, isRequestSuccessfull } =
        useSubmitHiredFormData();
    const { getRecruitmentProcesses } = useRecruitmentProcess();

    const {
        candidateId,
        id: recruitmentProccessId,
        external: { id: flowId },
    } = recruitmentProcessData;

    useEffect(() => {
        if (isRequestSuccessfull) setIsDialogOpen(false);
        return () => {
            if (isRequestSuccessfull) getRecruitmentProcesses(candidateId);
        };
    }, [
        candidateId,
        isRequestSuccessfull,
        getRecruitmentProcesses,
        setIsDialogOpen,
    ]);

    const unemployedOptions =
        GlobalAppDataStorageService.getUnemployedOptions();

    const rawSpecializationOptions =
        GlobalAppDataStorageService.getErganiSpecializationCodes();

    const educationLevelOptions =
        GlobalAppDataStorageService.getErganiEducationLevels();

    const specializationOptions =
        GeneralUtilityService.simplifySpecializationOptions(
            rawSpecializationOptions
        );

    const rawRecruiters = useSelector((state) => state.recruitersAccounts.data);
    const recruiterOptions =
        GeneralUtilityService.simplifyRecruiterData(rawRecruiters);

    const {
        firstName,
        lastName,
        birthdate,
        personalDetailsAndPreferences: {
            personalInformation: { gender },
        },
    } = candidate;

    const requiredFields = [
        {
            name: "Birth Date",
            value: birthdate,
            helpText:
                "Please locate the 'Date of Birth' field in the top section of the candidate's form.",
        },
        {
            name: "Gender",
            value: gender,
            helpText:
                "Please navigate to the 'Personal Details & Experience' tab and find the 'Gender' field under the 'Personal Information' section.    ",
        },
    ];

    const emptyFields = requiredFields
        .filter((field) => {
            if (field.value && typeof field.value === "object")
                return Object.keys(field.value).length === 0;
            if (field?.value === undefined) return true;
            return !field.value;
        })
        .map(({ name, helpText }) => ({ name, helpText }));

    const { company, position, recruiter, hiredInfo } = mapRecruitmentData(
        recruitmentProcessData
    );

    const prefilledStartAdornmentLocked = {
        startAdornment: (
            <InputAdornment position="start">
                <LockOutlinedIcon
                    sx={{
                        color: "gray.medium",
                    }}
                    fontSize="small"
                />
            </InputAdornment>
        ),
    };

    if (Object.keys(emptyFields).length > 0) {
        return (
            <Alert
                sx={{
                    mt: 2,
                }}
                severity="warning"
            >
                <Stack spacing={1}>
                    <Typography>
                        Complete required data to proceed (hover for details)
                    </Typography>
                    <Stack spacing={1} direction="row">
                        {emptyFields.map((each, index) => (
                            <Tooltip key={index} title={each.helpText}>
                                <Chip
                                    sx={{ p: 2 }}
                                    color="warning"
                                    label={each.name}
                                />
                            </Tooltip>
                        ))}
                    </Stack>
                </Stack>
            </Alert>
        );
    }

    const erganiSpecializationCodeDefault =
        hiredInfo?.ergani_specialization_code_id &&
        GeneralUtilityService.findObjectInArray(
            hiredInfo?.ergani_specialization_code_id,
            specializationOptions
        );

    const recruiterDefaultId =
        hiredInfo?.recruiter_id &&
        GeneralUtilityService.findObjectInArray(
            hiredInfo?.recruiter_id,
            recruiterOptions
        );

    const unemployedDefault =
        hiredInfo &&
        unemployedOptions.find(
            (option) => option.id === (hiredInfo?.unemployed ? 1 : 0)
        );

    const defaultValues = {
        firstName: hiredInfo?.first_name || firstName.toUpperCase(), // should be upper case
        lastName: hiredInfo?.last_name || lastName.toUpperCase(), // should be upper case
        company: hiredInfo?.company || company.name,
        position: hiredInfo?.position || position.name,
        grossYearSalary: hiredInfo?.gross_year_salary || "",
        dateOfOfferAcceptance:
            hiredInfo?.date_of_offer_acceptance ||
            format(new Date(), "yyyy-MM-dd"), // Prefill todays date, since it will be close to hired date
        firstDayOfWork:
            hiredInfo?.first_day_of_work || format(new Date(), "yyyy-MM-dd"), // Prefill todays date, since it will be closer to first day of work
        recruiter:
            recruiterDefaultId ||
            GeneralUtilityService.simplifyRecruiterData(recruiter),
        gender, // Cannot be updated in this form
        birthdate: format(new Date(birthdate), "yyyy-MM-dd"), // Cannot be updated in this form
        educationLevel: hiredInfo?.education_level || null,
        erganiSpecializationCode: erganiSpecializationCodeDefault || null,
        unemployed: unemployedDefault || null,
    };

    const handleSubmit = (formData) => {
        const {
            gender,
            birthdate,
            erganiSpecializationCode,
            unemployed,
            recruiter,
        } = formData;

        /*
         ** Remove specified keys as they are exclusively
         ** on the front-end to compute the actual state
         ** that will be sent to the back-end.
         ** Deleting these keys ensures that
         ** only necessary data is transmitted.
         */
        const keysToDelete = [
            "company",
            "position",
            "birthdate",
            "erganiSpecializationCode",
            "recruiter",
        ];

        const transformedFormData = {
            ...formData,
            recruiterId: recruiter.id,
            gender: gender.id,
            age: differenceInYears(new Date(), parseISO(birthdate)),
            erganiSpecializationCodeId: erganiSpecializationCode.id,
            unemployed: unemployed.id,
        };

        const finalDataToSend = { ...transformedFormData };
        keysToDelete.forEach((key) => {
            delete finalDataToSend[key];
        });

        const endpointParams = {
            candidateId,
            recruitmentProccessId,
            flowId,
        };

        submitHiredFormData(endpointParams, finalDataToSend);
    };

    return (
        <Form
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            containerProps={{ gap: 2, sx: { py: 2 } }}
        >
            {isAlertVisible && (
                <Alert
                    severity="info"
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setIsAlertVisible(false);
                            }}
                        >
                            OK
                        </Button>
                    }
                >
                    <Typography>
                        For Greek candidates, please enter Name & Surname in
                        Greek. For all other candidates, use English.
                    </Typography>
                </Alert>
            )}

            {/* Name - Surname */}
            <Stack {...stackProps} direction="row">
                <Form.TextField
                    required
                    name="firstName"
                    label="Name"
                    onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                    }}
                />
                <Form.TextField
                    required
                    name="lastName"
                    label="Surname"
                    onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                    }}
                />
            </Stack>

            {/* Company - Position */}
            <Stack {...stackProps} direction="row">
                <Form.TextField
                    disabled={true}
                    name="company"
                    label="Company"
                    InputProps={prefilledStartAdornmentLocked}
                />
                <Form.TextField
                    disabled={true}
                    name="position"
                    label="Position"
                    InputProps={prefilledStartAdornmentLocked}
                />
            </Stack>

            {/* Gross Yearly Salary - Date Of Offer Acceptance */}
            <Stack {...stackProps} direction="row">
                <Form.TextField
                    required
                    name="grossYearSalary"
                    label="Gross Yearly Hired Salary"
                    type="number"
                />

                <Form.TextField
                    required
                    name="dateOfOfferAcceptance"
                    label="Date of offer acceptance"
                    type="date"
                    textFieldProps={{ InputLabelProps: { shrink: true } }}
                />
            </Stack>

            {/* First Day Of Work - Recruiter */}
            <Stack {...stackProps} direction="row">
                <Form.TextField
                    required
                    name="firstDayOfWork"
                    label="First day of work"
                    type="date"
                    textFieldProps={{ InputLabelProps: { shrink: true } }}
                />
                <Form.Autocomplete
                    required
                    options={recruiterOptions}
                    name="recruiter"
                    label="Recruiter"
                />
            </Stack>

            {/* ERGANI-ONLY SPECIFIC INFO */}
            <Form.Section
                required
                title="ERGANI SPECIFIC INFO"
                wrapperStyles={{ mt: 1 }}
            >
                <Stack {...stackProps}>
                    <Stack {...stackProps} direction="row">
                        <Form.Autocomplete
                            required
                            name="gender"
                            label="Gender"
                            disabled={true}
                            textFieldProps={{
                                InputProps: {
                                    ...prefilledStartAdornmentLocked,
                                },
                            }}
                        />
                        <Form.TextField
                            required
                            name="birthdate"
                            label="Birthdate"
                            type="date"
                            disabled={true}
                            InputProps={prefilledStartAdornmentLocked}
                        />
                    </Stack>
                    <Stack {...stackProps} direction="row">
                        <Form.Autocomplete
                            name="educationLevel"
                            label="Education level"
                            required={true}
                            options={educationLevelOptions}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) =>
                                option === value
                            }
                        />
                        <Form.Autocomplete
                            name="unemployed"
                            label="Unemployed"
                            options={unemployedOptions}
                            required={true}
                        />
                    </Stack>
                    <Stack {...stackProps} direction="row">
                        <Form.Autocomplete
                            required
                            name="erganiSpecializationCode"
                            label="Specialization"
                            options={specializationOptions}
                        />
                    </Stack>
                </Stack>
            </Form.Section>

            <Form.LoadingButton
                loading={isLoading}
                type="submit"
                styles={{ mt: 4 }}
            >
                Save changes
            </Form.LoadingButton>
        </Form>
    );
};

export default NewHireDataForm;
