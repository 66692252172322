import React, { useEffect, useState, useRef } from "react";
import { Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginService from "./Login.service";
import "./login.scss";
import { useDispatch } from "react-redux";
import { login } from "reducers/userValuesSlice";
import { closeModal } from "reducers/authModal";
import { useNavigate } from "react-router-dom";

function Login({ isModal }) {
    const isMounted = useRef(true);

    const icon = "icon";
    const errorIcon = "icon-error";
    const errorMessage = "Credentials are not correct, please try again";

    const [error, setError] = useState(null);
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [requestIsPending, setRequestIsPending] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitLoginForm = async (e) => {
        e.preventDefault();
        try {
            setRequestIsPending(true);
            await LoginService.loginUser({ ...credentials });
            dispatch(login());
            dispatch(closeModal());
            if (!isModal) {
                navigate("/");
            }
            setRequestIsPending(false);
        } catch (e) {
            setRequestIsPending(false);
            setError(errorMessage);
        }
    };

    let labelIcon = error ? errorIcon : icon;
    let isButtonDisabled =
        credentials.email === "" || credentials.password === "";

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div className="app-container">
            <div className="login-form">
                <form onSubmit={submitLoginForm}>
                    <div className="form-inner">
                        {isModal ? (
                            <h3 className="form-title">
                                Session expired. Please login again
                            </h3>
                        ) : (
                            <h2 className="form-title">Login</h2>
                        )}
                        <div className="form-group form-group-email">
                            <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                onChange={(e) =>
                                    setCredentials({
                                        ...credentials,
                                        email: e.target.value,
                                    })
                                }
                                value={credentials.email}
                            />
                            <span className="input-label-section">
                                <svg className={labelIcon}></svg>
                            </span>
                        </div>
                        <div className="form-group form-group-password">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                autoComplete="on"
                                onChange={(e) =>
                                    setCredentials({
                                        ...credentials,
                                        password: e.target.value,
                                    })
                                }
                                value={credentials.password}
                            />
                            <span className="input-label-section">
                                <svg className={labelIcon}></svg>
                            </span>
                        </div>
                        <div className="form-group-errors">
                            <div
                                className={
                                    error ? "form-group error" : "form-group"
                                }
                            >
                                {error ? (
                                    <Alert severity="error">{error}</Alert>
                                ) : null}
                            </div>
                        </div>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            label="Submit"
                            id="submit-login"
                            disabled={isButtonDisabled}
                            loading={requestIsPending}
                        >
                            Login
                        </LoadingButton>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
