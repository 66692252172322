import { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAddStep } from "components/hooks/useRecruitmentProcess";
import SaveIcon from "@mui/icons-material/Save";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import { GlobalAppDataStorageService } from "infra/storage";
import getErrorMessage from "assets/data/errors.json";
import ReProService from "services/RecruitmentProccessCard.service";

export const AddNewState = ({
    title,
    setIsDialogOpen,
    cardData,
    isInternalTabActive,
}) => {
    const candidate = useSelector((state) => state.currentCandidate);
    const { addStep, isLoading, requestSuccessful } = useAddStep();

    const recruitmentProccessCurrentId =
        ReProService.getRecruitmentProccessCurrentId(cardData);

    const {
        handleSubmit,
        register,
        watch,
        setError,
        formState: { errors },
    } = useForm();

    //TODO: Refactor this logic to a more centralized solution
    const statuses = {
        DROPPED: { INTERNAL_GENERAL: 10, EXTERNAL: 11, INTERNAL_SPECIFIC: 12 },
        REJECTED: { INTERNAL_GENERAL: 7, EXTERNAL: 8, INTERNAL_SPECIFIC: 9 },
        SHORTLISTED: 13,
        PENDING: { INTERNAL_GENERAL: 1, EXTERNAL: 2, INTERNAL_SPECIFIC: 3 },
    };

    const watchStatus = watch("rpStepStatusId");

    const watchState = watch("rpStepStateId");

    const isStatusRejected = [
        statuses.REJECTED.INTERNAL_GENERAL,
        statuses.REJECTED.INTERNAL_SPECIFIC,
        statuses.REJECTED.EXTERNAL,
    ].includes(watchStatus);

    const isStatusShortlisted = statuses.SHORTLISTED === watchStatus;

    const isStatusDropped = [
        statuses.DROPPED.INTERNAL_GENERAL,
        statuses.DROPPED.INTERNAL_SPECIFIC,
        statuses.DROPPED.EXTERNAL,
    ].includes(watchStatus);

    useEffect(() => {
        if (requestSuccessful) {
            setIsDialogOpen(false);
        }
    }, [requestSuccessful, setIsDialogOpen]);

    const flow = isInternalTabActive ? "internal" : "external";

    const submitForm = async (data) => {
        const { rpStepStateId, rpStepStatusId } = data;

        if (!rpStepStateId || !rpStepStatusId) {
            setError("rpStepStateId", {
                type: "manual",
                message: "State should not be empty",
            });

            setError("rpStepStatusId", {
                type: "manual",
                message: "Status should not be empty",
            });
            return;
        }

        const endpointParams = {
            candidateId: candidate.id,
            recruitmentProccessId: cardData.id,
            flowId: cardData[flow]?.id,
        };

        if (!isLoading) {
            data.score = data.score && Number(data.score).toFixed(1);
            await addStep(endpointParams, data);
        }
    };

    const stateOptions = GlobalAppDataStorageService.getRpStatesOptions();
    const statusOptions = GlobalAppDataStorageService.getRpStatusesOptions();

    const justOptions =
        GlobalAppDataStorageService.getRpJustificationsOptions();

    const filterJustOptions = ReProService.filterOptionsByCategoryId(
        justOptions,
        recruitmentProccessCurrentId
    );

    const statusOptionsFiltered = ReProService.filterStatuses(
        statusOptions,
        isInternalTabActive,
        watchState,
        recruitmentProccessCurrentId
    );

    const setDefaultStatusId = () => {
        const defaultStatusId = statusOptionsFiltered.find((option) => {
            return (
                option.id === statuses.PENDING.INTERNAL_GENERAL ||
                option.id === statuses.PENDING.EXTERNAL ||
                option.id === statuses.PENDING.INTERNAL_SPECIFIC
            );
        });
        return defaultStatusId ? defaultStatusId.id : null;
    };

    const defaultStatus = setDefaultStatusId();

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title}</Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
                <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                    <TextField
                        select
                        fullWidth
                        defaultValue={candidate?.state || ""}
                        name="rpStepStateId"
                        label="State"
                        {...register("rpStepStateId", {
                            required: getErrorMessage.rpStepStateId.required,
                        })}
                        error={Boolean(errors.rpStepStateId)}
                        helperText={
                            errors.rpStepStateId
                                ? errors.rpStepStateId.message
                                : ""
                        }
                    >
                        {ReProService.filterOptionsByCategoryId(
                            stateOptions,
                            recruitmentProccessCurrentId
                        ).map((option) => {
                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.state.name || ""}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        name="rpStepStatusId"
                        defaultValue={defaultStatus}
                        label="Status"
                        {...register("rpStepStatusId", {
                            required: getErrorMessage.rpStepStatusId.required,
                        })}
                        error={Boolean(errors.rpStepStatusId)}
                        helperText={
                            errors.rpStepStatusId
                                ? errors.rpStepStatusId.message
                                : ""
                        }
                    >
                        {statusOptionsFiltered.map((option) => {
                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.status.name || ""}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </HorizontalFlex>

                {isStatusShortlisted && (
                    <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                        <Stack sx={{ width: "100%" }}>
                            <Stack direction="row" gap={2}>
                                <TextField
                                    sx={{ minWidth: "150px" }}
                                    label="Score"
                                    required
                                    defaultValue={""}
                                    {...register("score", {})}
                                    inputProps={{
                                        step: 0.5,
                                        min: 0,
                                        max: 10,
                                        type: "number",
                                        "aria-labelledby": "input-slider",
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </HorizontalFlex>
                )}
                {isStatusShortlisted && (
                    <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                        <TextField
                            {...register("report")}
                            required
                            fullWidth
                            multiline
                            minRows={4}
                            name="report"
                            defaultValue={""}
                            label="Report"
                        />
                    </HorizontalFlex>
                )}

                {isStatusDropped || isStatusRejected ? (
                    <HorizontalFlex>
                        <TextField
                            select
                            fullWidth
                            required
                            name="rpStepJustificationId"
                            defaultValue={candidate?.status || ""}
                            {...register("rpStepJustificationId", {})}
                            label="Justification"
                        >
                            {filterJustOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.justification.name || ""}
                                </MenuItem>
                            ))}
                        </TextField>
                    </HorizontalFlex>
                ) : null}
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default AddNewState;
