import axios from "utils/axiosConfig";
import { useState } from "react";
import { urlService } from "infra/envs/";
import useNotifications from "components/hooks/useNotifications";
import { useDispatch } from "react-redux";
import {
    setRecruitmentProcessesStart,
    setCandidateRecruitmentProcesses,
    addCandidateRecruitmentProcess,
    updateCandidateRecruitmentProcess,
} from "reducers/recruitmentProcessesSlice";
import { updateCandidateVersion } from "reducers/currentCandidateSlice";

const extractErrorMessage = (errorObject) => {
    const keys = Object.keys(errorObject);
    if (keys.length === 0) return null;

    const firstKey = keys[0];
    const firstErrorMessage = errorObject[firstKey];

    // If it's an array, join the messages together with a space.
    const errorMessage = Array.isArray(firstErrorMessage)
        ? firstErrorMessage.join(" ")
        : firstErrorMessage;

    return `${errorMessage}`;
};

const ACTIONS = {
    ADD_RECRUITMENT_PROCESS: "addRecruitmentProcesses",
    FETCH_RECRUITMENT_PROCESSES: "fetchRecruitmentProcesses",
    UPDATE_RECRUITMENT_PROCESS_STATE: "updateRecruitmentProcessState",
    CREATE_NOTE: "createNote",
    EDIT_NOTE: "editNote",
    DELETE_NOTE: "deleteNote",
    ADD_STEP: "addStep",
    ATTACH_JOB: "attachJob",
    SUBMIT_HIRED_FORM_DATA: "submitHiredFormData"
};

const generateEndpoint = (action, params) => {
    const {
        candidateId,
        recruitmentProccessId,
        flowId,
        rpStepStateId,
        noteId,
    } = params;

    const getCandidateId = params;

    switch (action) {
        case ACTIONS.ADD_RECRUITMENT_PROCESS:
            return `${urlService.CANDIDATES_API_PATH}/${getCandidateId}/recruitment-processes`;

        case ACTIONS.FETCH_RECRUITMENT_PROCESSES:
            return `${urlService.CANDIDATES_API_PATH}/${getCandidateId}/recruitment-processes`;

        case ACTIONS.UPDATE_RECRUITMENT_PROCESS_STATE:
            return `${urlService.CANDIDATES_API_PATH}/${candidateId}/recruitment-processes/${recruitmentProccessId}/flows/${flowId}/steps/${rpStepStateId}/statuses`;

        case ACTIONS.CREATE_NOTE:
            return `${urlService.CANDIDATES_API_PATH}/${candidateId}/recruitment-processes/${recruitmentProccessId}/flows/${flowId}/steps/${rpStepStateId}/notes`;

        case ACTIONS.EDIT_NOTE:
            return `${urlService.CANDIDATES_API_PATH}/${candidateId}/recruitment-processes/${recruitmentProccessId}/flows/${flowId}/steps/${rpStepStateId}/notes/${noteId}`;

        case ACTIONS.DELETE_NOTE:
            return `${urlService.CANDIDATES_API_PATH}/${candidateId}/recruitment-processes/${recruitmentProccessId}/flows/${flowId}/steps/${rpStepStateId}/notes/${noteId}`;

        case ACTIONS.ADD_STEP:
            return `${urlService.CANDIDATES_API_PATH}/${candidateId}/recruitment-processes/${recruitmentProccessId}/flows/${flowId}/steps`;

        case ACTIONS.ATTACH_JOB:
            return `${urlService.CANDIDATES_API_PATH}/${candidateId}/recruitment-processes/${recruitmentProccessId}/jobs`;

        case ACTIONS.SUBMIT_HIRED_FORM_DATA:
            return `${urlService.CANDIDATES_API_PATH}/${candidateId}/recruitment-processes/${recruitmentProccessId}/flows/${flowId}/hired_infos`;
        
        default:
            throw new Error(`Unsupported action: ${action}`);
    }
};

export const useRecruitmentProcess = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [isUpdateNoteLoading, setIsUpdateNoteLoading] = useState(false);
    const [updateNoteRequestSuccessful, setUpdateNoteRequestSuccessful] =
        useState(false);
    const [isDeleteNoteLoading, setIsDeleteNoteLoading] = useState(false);
    const [deleteNoteRequestSuccessful, setDeleteNoteRequestSuccessful] =
        useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const getRecruitmentProcesses = async (candidateId) => {
        setIsLoading(true);
        dispatch(setRecruitmentProcessesStart());
        const endpoint = generateEndpoint(
            ACTIONS.FETCH_RECRUITMENT_PROCESSES,
            candidateId
        );
        try {
            const response = await axios.get(endpoint);
            const candidateRecruitmentProcesses = response.data;
            dispatch(
                setCandidateRecruitmentProcesses(candidateRecruitmentProcesses)
            );
            dispatch(updateCandidateVersion())
            setRequestSuccessful(true);
            setIsLoading(false);
            return candidateRecruitmentProcesses;
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to fetch recruitment processes");
        } finally {
            setIsLoading(false);
        }
    };

    const updateRecruitmentProcessState = async (
        endpointParams,
        updateData
    ) => {
        setIsLoading(true);
        const endpoint = generateEndpoint(
            ACTIONS.UPDATE_RECRUITMENT_PROCESS_STATE,
            endpointParams
        );
        try {
            const response = await axios.post(endpoint, updateData);
            dispatch(updateCandidateRecruitmentProcess(response.data));
            dispatch(updateCandidateVersion())
            setRequestSuccessful(true);
            setIsLoading(false);
            renderNotification(`Status succesfully changed`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to update status");
        } finally {
            setIsLoading(false);
        }
    };

    const createRecruitmentProcessNote = async (endpointParams, updateData) => {
        setIsLoading(true);
        const endpoint = generateEndpoint(ACTIONS.CREATE_NOTE, endpointParams);
        try {
            const response = await axios.post(endpoint, updateData);

            dispatch(updateCandidateRecruitmentProcess(response.data));
            dispatch(updateCandidateVersion())
            setRequestSuccessful(true);
            setIsLoading(false);
            renderNotification(`Note succesfully created`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to create note");
        } finally {
            setIsLoading(false);
        }
    };

    const updateRecruitmentProcessNote = async (endpointParams, updateData) => {
        setIsUpdateNoteLoading(true);
        const endpoint = generateEndpoint(ACTIONS.EDIT_NOTE, endpointParams);
        try {
            const response = await axios.patch(endpoint, updateData);
            dispatch(updateCandidateRecruitmentProcess(response.data));
            dispatch(updateCandidateVersion())
            setUpdateNoteRequestSuccessful(true);
            setIsUpdateNoteLoading(false);
            renderNotification(`Succesfully updated note`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setUpdateNoteRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to update note");
        } finally {
            setIsUpdateNoteLoading(false);
        }
    };

    const deleteRecruitmentProcessNote = async (endpointParams) => {
        setIsDeleteNoteLoading(true);
        const endpoint = generateEndpoint(ACTIONS.DELETE_NOTE, endpointParams);
        try {
            const response = await axios.delete(endpoint);
            dispatch(updateCandidateRecruitmentProcess(response.data));
            dispatch(updateCandidateVersion())
            setDeleteNoteRequestSuccessful(true);
            setIsDeleteNoteLoading(false);
            renderNotification(`Succesfully deleted note`);
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setDeleteNoteRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to delete note");
        } finally {
            setIsDeleteNoteLoading(false);
        }
    };

    return {
        getRecruitmentProcesses,
        updateRecruitmentProcessState,
        createRecruitmentProcessNote,
        updateRecruitmentProcessNote,
        deleteRecruitmentProcessNote,
        isLoading,
        requestSuccessful,
        isUpdateNoteLoading,
        updateNoteRequestSuccessful,
        isDeleteNoteLoading,
        deleteNoteRequestSuccessful,
    };
};

export const useAddRecuitmentProcess = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const addRecuitmentProcess = async (candidateId, data) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                generateEndpoint(ACTIONS.ADD_RECRUITMENT_PROCESS, candidateId),
                data,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            const candidateRecruitmentProcesses = response.data;
            dispatch(
                addCandidateRecruitmentProcess(candidateRecruitmentProcesses)
            );
            dispatch(updateCandidateVersion())
            setRequestSuccessful(true);
            renderNotification("Recruitment process created successfully");
            setIsLoading(false);
            return { status: response.status };
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to create recruitment process");
        } finally {
            setIsLoading(false);
        }
    };

    return { addRecuitmentProcess, isLoading, requestSuccessful };
};

export const useAddStep = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const addStep = async (endpointParams, data) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                generateEndpoint(ACTIONS.ADD_STEP, endpointParams),
                data,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            dispatch(updateCandidateRecruitmentProcess(response.data));
            dispatch(updateCandidateVersion())
            setRequestSuccessful(true);
            renderNotification("Step created successfully");
            setIsLoading(false);
            return { status: response.status };
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to create step");
        } finally {
            setIsLoading(false);
        }
    };

    return { addStep, isLoading, requestSuccessful };
};

export const useAttachJobs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const attachJobs = async (endpointParams, data) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                generateEndpoint(ACTIONS.ATTACH_JOB, endpointParams),
                data,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            dispatch(addCandidateRecruitmentProcess(response.data));
            dispatch(updateCandidateVersion())
            setRequestSuccessful(true);
            renderNotification("Attached job successfully");
            setIsLoading(false);
            return { status: response.status };
        } catch (error) {
            const errorMessage = extractErrorMessage(error.response.data);
            setRequestSuccessful(false);
            renderErrorNotification(errorMessage);
            throw new Error("Failed to attach job");
        } finally {
            setIsLoading(false);
        }
    };

    return { attachJobs, isLoading, requestSuccessful };
};

export const useSubmitHiredFormData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestSuccessfull, setIsRequestSuccessfull] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();

    const submitHiredFormData = async (endpointParams, formData) => {
        setIsLoading(true);
        const endpoint = generateEndpoint(ACTIONS.SUBMIT_HIRED_FORM_DATA, endpointParams);

        try {
            const response = await axios.post(
                endpoint,
                formData,
            );
            const {data:{ message }} = await response;
            renderNotification(message);
            setIsRequestSuccessfull(true)
        } catch (error) {
            const errorMessage = extractErrorMessage(error?.response?.data);
            renderErrorNotification(errorMessage);
        } finally {
            setIsLoading(false);
            setIsRequestSuccessfull(false)
        }
    };

    return { submitHiredFormData, isRequestSuccessfull, isLoading };


}