import { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Stack,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    TextField,
    MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import { GlobalAppDataStorageService } from "infra/storage";
import { useRecruitmentProcess } from "components/hooks/useRecruitmentProcess";
import ReProService from "services/RecruitmentProccessCard.service";
import MeteredButton from "components/lib-ui/MeteredButton";

export const RpNoteForm = ({
    title,
    setIsDialogOpen,
    cardData,
    isEditMode = false,
}) => {
    const rpNoteCategories = GlobalAppDataStorageService.getRpNoteCategories();
    const candidate = useSelector((state) => state.currentCandidate);
    const [flowId, setFlowId] = useState();
    const { handleSubmit, register, control } = useForm();
    const {
        createRecruitmentProcessNote,
        updateRecruitmentProcessNote,
        deleteRecruitmentProcessNote,
        isLoading,
        requestSuccessful,
        isUpdateNoteLoading,
        updateNoteRequestSuccessful,
        isDeleteNoteLoading,
        deleteNoteRequestSuccessful,
    } = useRecruitmentProcess();

    const isShortlisted = cardData?.shortlisted;
    const recruitmentProccessId = cardData?.id;
    const recruitmentProcessStepOptions =
        cardData.recruitmentProcessStepOptions;
    const activeFlowData = cardData[cardData.activeFlowTab];
    const latestStepStateId =
        ReProService.getFlowLatestrpStepState(activeFlowData)?.id;
    const filterStepOptions = recruitmentProcessStepOptions.filter(
        (each) => each.id === latestStepStateId
    );
    const filterStepOptionsOnEdit = recruitmentProcessStepOptions.filter(
        (each) => each.id === cardData.noteData?.rpStepState?.id
    );
    const latestStep = filterStepOptions[0];
    const currentStep = filterStepOptionsOnEdit[0];

    useEffect(() => {
        if (
            requestSuccessful ||
            updateNoteRequestSuccessful ||
            deleteNoteRequestSuccessful
        ) {
            setIsDialogOpen(false);
        }
    }, [
        requestSuccessful,
        updateNoteRequestSuccessful,
        deleteNoteRequestSuccessful,
        setIsDialogOpen,
    ]);

    useEffect(() => {
        isEditMode
            ? setFlowId(currentStep?.flowId)
            : setFlowId(latestStep?.flowId);
    }, [isEditMode, currentStep?.flowId, latestStep?.flowId]);

    const isNoteOperationInProgress = () => {
        return isLoading || isUpdateNoteLoading || isDeleteNoteLoading;
    };

    const shouldDisableNotes = isNoteOperationInProgress();

    const getRpNoteCategories = (isShortlisted) => {
        if (!isShortlisted)
            return rpNoteCategories.filter((category) => category.id === 3);
        return rpNoteCategories;
    };

    const defaultNoteState = isEditMode
        ? currentStep?.stepId
        : latestStep?.stepId;

    const defaultNoteBody = isEditMode ? cardData.noteData?.body : "";

    const handleDeleteNote = (event) => {
        const endpointParams = {
            candidateId: candidate.id,
            recruitmentProccessId: recruitmentProccessId,
            flowId: flowId,
            rpStepStateId: currentStep?.stepId,
            noteId: cardData.noteData.id,
        };
        deleteRecruitmentProcessNote(endpointParams);
    };

    const submitForm = async (data) => {
        if (isEditMode) {
            if (!isLoading) {
                const endpointParams = {
                    candidateId: candidate.id,
                    recruitmentProccessId: recruitmentProccessId,
                    flowId: flowId,
                    rpStepStateId: data.state,
                    noteId: cardData.noteData.id,
                };
                await updateRecruitmentProcessNote(endpointParams, data);
            }
        } else {
            if (!isLoading) {
                const endpointParams = {
                    candidateId: candidate.id,
                    recruitmentProccessId: recruitmentProccessId,
                    flowId: flowId,
                    rpStepStateId: data.state,
                };
                await createRecruitmentProcessNote(endpointParams, data);
            }
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title}</Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
                <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                    <TextField
                        select
                        fullWidth
                        defaultValue={defaultNoteState || ""}
                        name="state"
                        required
                        {...register("state", {})}
                        label="State"
                        onChange={(event) => {
                            const selectedId = event.target.value;
                            const selectedOption =
                                recruitmentProcessStepOptions?.find(
                                    (option) => option.stepId === selectedId
                                );
                            setFlowId(selectedOption.flowId);
                        }}
                    >
                        {recruitmentProcessStepOptions?.map((option, index) => (
                            <MenuItem key={index} value={option.stepId}>
                                {option.name || ""}
                            </MenuItem>
                        ))}
                    </TextField>
                </HorizontalFlex>
                <HorizontalFlex sx={{ my: 3 }}>
                    <Stack direction="column" alignItems="flex-start">
                        <FormLabel id="rpNoteCategoryId">
                            Note Category
                        </FormLabel>
                        <Controller
                            name="rpNoteCategoryId"
                            control={control}
                            defaultValue="3"
                            render={({ field }) => (
                                <RadioGroup {...field}>
                                    <Stack direction="row">
                                        {getRpNoteCategories(isShortlisted).map(
                                            (rpNoteCategory, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={
                                                            rpNoteCategory.id
                                                        }
                                                        control={<Radio />}
                                                        label={
                                                            rpNoteCategory.name
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                    </Stack>
                                </RadioGroup>
                            )}
                        />
                    </Stack>
                </HorizontalFlex>
                <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                    <TextField
                        {...register("noteBody")}
                        fullWidth
                        multiline
                        minRows={4}
                        required
                        name="noteBody"
                        defaultValue={defaultNoteBody}
                        label="Note"
                    />
                </HorizontalFlex>
            </Box>

            <Stack
                direction="row"
                justifyContent={isEditMode ? "space-between" : "flex-end"}
            >
                {isEditMode ? (
                    <MeteredButton
                        onActionComplete={handleDeleteNote}
                        isDisabled={shouldDisableNotes}
                        loading={isDeleteNoteLoading}
                        buttonProps={{
                            color: "gray",
                            startIcon: <DeleteOutlinedIcon />,
                        }}
                    >
                        Delete
                    </MeteredButton>
                ) : (
                    <Button
                        color="gray"
                        variant="text"
                        onClick={() => {
                            setIsDialogOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                )}

                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading || isUpdateNoteLoading}
                    disabled={shouldDisableNotes}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default RpNoteForm;
