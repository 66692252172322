import { useState } from "react";
import { Paper, Typography, Divider, Chip, Stack } from "@mui/material";
import { Box } from "@mui/system";
import IconContent from "../../../../components/lib-ui/IconContent";
import IconBtn from "../../../../components/lib-ui/IconBtn";
import DialogBox from "components/lib-ui/DialogBox";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import PlaceholderText from "./PlaceholderText";
import EducationForm from "features/candidate/components/candidate-form/EducationForm";
import { formatDistance } from "date-fns";

export const EducationCard = ({ educationData }) => {
    const {
        university,
        educationLevel,
        department,
        grade,
        start_at,
        end_at,
        academic_fields,
        updated_at,
    } = educationData;

    const universityName = university?.name;
    const educationLevelName = educationLevel?.name;
    const departmentName = department?.name;
    const endDate = end_at ? end_at : "Present";
    const updatedDate = new Date(updated_at);
    const timeDiff = formatDistance(updatedDate, new Date());

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const isCurrently =
        endDate === "Present" ? <Chip label="Currently" /> : null;

    const departmentAndEducationLevel = (
        departmentName,
        educationLevelName
    ) => {
        if (departmentName && educationLevelName) {
            return (
                <>
                    {departmentName}, {educationLevelName}
                </>
            );
        }
        if (departmentName && !educationLevelName) {
            return <>{departmentName}</>;
        }
        if (!departmentName && educationLevelName) {
            return <>{educationLevelName}</>;
        }
    };

    const universityAndEducationLevel = (
        universityName,
        educationLevelName
    ) => {
        if (universityName && educationLevelName) {
            return (
                <>
                    {universityName}, {educationLevelName}
                </>
            );
        }
        if (universityName && !educationLevelName) {
            return <>{universityName}</>;
        }
        if (!universityName && educationLevelName) {
            return <>{educationLevelName}</>;
        }
        return <PlaceholderText returnOnlyText={true} />;
    };

    const departmentAndGrade = (departmentName, grade) => {
        if (departmentName && grade) {
            return (
                <>
                    {departmentName} • Grade: {grade}
                </>
            );
        }
        if (departmentName && !grade) {
            return <>{departmentName}</>;
        }
        if (!departmentName && grade) {
            return <>Grade: {grade}</>;
        }
        return <PlaceholderText returnOnlyText={true} />;
    };

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: "10px",
                width: "600px",
                height: "fit-content",
            }}
        >
            <Box
                p={2}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                }}
            >
                <IconBtn
                    id="edit_education"
                    defaulted={true}
                    sx={{
                        top: 0,
                        right: 0,
                        border: "unset",
                        alignSelf: "start",
                    }}
                    icon={EditOutlinedIcon}
                    iconStyles={{ fontSize: "1.2rem" }}
                    onClick={handleDialogOpen}
                ></IconBtn>
                <Stack>
                    {/* Department - Education Level | Education Title */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                        >
                            {departmentAndEducationLevel(
                                departmentName,
                                educationLevelName
                            )}
                        </Typography>
                        {isCurrently}
                    </Box>
                    <Typography color="gray.medium">{timeDiff} ago</Typography>
                </Stack>
            </Box>
            <Divider />
            <Box>
                {/* University - Education Level */}
                <IconContent
                    text={universityAndEducationLevel(
                        universityName,
                        educationLevelName
                    )}
                    sx={{ px: 2, py: 1 }}
                    icon={SchoolOutlinedIcon}
                    tooltipText={"University & Education level"}
                    tooltipPlacement={"left"}
                />
                {/* Department - Grade */}
                <IconContent
                    text={departmentAndGrade(departmentName, grade)}
                    sx={{ px: 2, py: 1 }}
                    icon={InfoOutlinedIcon}
                    tooltipText={"Department & Grade"}
                    tooltipPlacement={"left"}
                />
                {/* Start Year - End Year */}
                <IconContent
                    text={
                        start_at || end_at ? (
                            <>
                                {start_at} - {endDate}
                            </>
                        ) : (
                            <PlaceholderText returnOnlyText={true} />
                        )
                    }
                    sx={{ px: 2, py: 1 }}
                    icon={DateRangeOutlinedIcon}
                    tooltipText={"Start date - End date"}
                    tooltipPlacement={"left"}
                />
                {/* Sectors */}
                <IconContent
                    sx={{ px: 2, py: 1 }}
                    icon={ExtensionOutlinedIcon}
                    tooltipText={"Academic fields"}
                    tooltipPlacement={"left"}
                >
                    <Box gap={2} sx={{ display: "flex", flexWrap: "wrap" }}>
                        {academic_fields ? (
                            academic_fields?.map((each) => (
                                <Chip
                                    key={each.id}
                                    color="primary"
                                    label={each.name}
                                />
                            ))
                        ) : (
                            <PlaceholderText returnOnlyText={true} />
                        )}
                    </Box>
                </IconContent>
            </Box>

            {/* Edit Education Popup */}
            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Edit education"
                floatCancel={true}
                showActionButtons={false}
            >
                <EducationForm
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    onSubmit={handleDialogClose}
                    isEditMode={true}
                    educationData={educationData}
                />
            </DialogBox>
        </Paper>
    );
};

export default EducationCard;
