import axios from "utils/axiosConfig";
import { useState, useEffect } from "react";
import CandidateService from "services/CandidateService";
import useNotifications from "components/hooks/useNotifications";

export const useCandidateCv = (id) => {
    const [isLoading, setIsLoading] = useState(false);
    const [candidateCvData, setCandidateCvData] = useState(null);
    const [candidateCvURL, setCandidateCvURL] = useState(null);

    useEffect(() => {
        const fetchCandidateCv = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${CandidateService.cvBaseURL}${id}`
                );

                if (response.data.message) {
                    setCandidateCvURL(null);
                } else {
                    setCandidateCvURL(`${CandidateService.cvBaseURL}${id}`);
                    setCandidateCvData(response.data);
                }
            } catch (error) {
                console.error("Error:", error);
                throw new Error("Failed to fetch candidate cv");
            } finally {
                setIsLoading(false);
            }
        };
        fetchCandidateCv();
    }, [id]);

    return { candidateCvData, candidateCvURL, isLoading };
};

export const useUploadCv = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const { renderNotification, renderErrorNotification } = useNotifications();

    const uploadCv = async (url, data) => {
        if (!url) {
            console.error("Error: URL is not defined");
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.post(url, data, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setRequestSuccessful(true);
            renderNotification("Updated successfully");
            setIsLoading(false);
            return { status: response.status };
        } catch (error) {
            setRequestSuccessful(false);
            renderErrorNotification(
                `Error: please make sure you uploaded a pdf file`
            );
            renderErrorNotification("Failed to update field");
            console.error("Error updating candidate:", error);
            setIsLoading(false);
            throw new Error("Error updating candidate");
        }
    };

    return { uploadCv, isLoading, requestSuccessful };
};
