import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconText from "components/lib-ui/IconContent";
import { TopEditDialog } from "features/candidate/components/candidate-form/TopEditDialog";
import IconBtn from "components/lib-ui/IconBtn";
import LinkedinFilled from "components/lib-ui/icons/LinkedinFilled";
import CollegelinkFilled from "components/lib-ui/icons/CollegelinkFilled";
import PlaceholderText from "./PlaceholderText";
import URLService from "infra/envs/URL.service";
import Utils from "services/GeneralUtilityService";
import MenuButton from "components/layout/profile-navigation/MenuButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Link from "@mui/material/Link";

const LINKEDIN_PREFIX = "https://www.linkedin.com/in/";
const COLLEGELINK_PREFIX = `${URLService.CANDIDATE_COLLEGELINK_PATH}/employer/candidate.php?student=`;

/**
 * TopSection component displays a candidate's basic information and provides edit functionality.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.sx - styles rename as parentStyles to override the default component styles. (Used to make top section stylable in ANY parent container)
 * @returns {React.Element} The TopSection component.
 */
export const TopSection = ({ sx: parentStyles, isInDrawer }) => {
    const candidate = useSelector((state) => state.currentCandidate);
    const candidateId = candidate?.id;
    const hasCv = candidate?.cv?.hasCv;
    const hasLinkedIn = candidate?.linkedin;

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const composeMilitary = () => {
        if (candidate?.military?.obligation === null) {
            return "";
        } else if (candidate?.military?.obligation === false) {
            return "Fulfilled";
        } else {
            const defermentText = candidate?.military?.deferment
                ? `(${candidate?.military?.deferment})`
                : "";
            return `Pending ${defermentText}`;
        }
    };

    const composeName =
        candidate?.firstName || candidate?.lastName ? (
            `${candidate?.firstName} ${candidate?.lastName}`
        ) : (
            <PlaceholderText returnOnlyText={true} />
        );
    const composeLocation =
        candidate?.country || candidate?.city ? (
            `${candidate?.city.name},${candidate?.country.name}`
        ) : (
            <PlaceholderText returnOnlyText={true} />
        );

    const composePhone =
        candidate?.phoneCode?.calling_code && candidate?.phone ? (
            `+ ${candidate?.phoneCode?.calling_code} ${candidate?.phone}`
        ) : !candidate?.phoneCode?.calling_code && candidate?.phone ? (
            candidate?.phone
        ) : (
            <PlaceholderText returnOnlyText={true} />
        );

    return (
        <Box
            sx={{
                ...parentStyles,
            }}
        >
            <Box
                gap={2}
                sx={{
                    alignItems: "center",
                    height: "100px",
                    maxWidth: "1000px",
                    display: "flex",
                    position: "relative",
                }}
            >
                <Avatar
                    alt="candidate picture"
                    src={null}
                    sx={{
                        width: 75,
                        height: 75,
                        backgroundColor: Utils.stringToColor(composeName),
                    }}
                >
                    {Utils.getStringInitals(composeName)}
                </Avatar>

                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="header1" component="h2">
                                {composeName}
                            </Typography>
                            <IconBtn
                                defaulted={true}
                                sx={{
                                    top: 0,
                                    right: 0,
                                    border: "unset",
                                }}
                                icon={EditOutlinedIcon}
                                iconStyles={{ fontSize: "1.2rem" }}
                                onClick={handleDialogOpen}
                            ></IconBtn>
                            {isInDrawer ? (
                                <Link
                                    href={`${URLService.CANDIDATE_PROFILE_PATH}/${candidate?.id}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <IconBtn icon={VisibilityIcon} />
                                </Link>
                            ) : null}
                        </Box>
                        {hasCv || hasLinkedIn ? (
                            <MenuButton
                                text="Parse this profile"
                                hasCv={hasCv}
                                hasLinkedIn={hasLinkedIn}
                                candidateId={candidateId}
                                variant="contained"
                            />
                        ) : null}
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Typography component="p">
                                {candidate?.headline}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Stack direction="row" component="ul" spacing={2}>
                            <IconText
                                icon={LocationOnOutlinedIcon}
                                text={composeLocation}
                            />
                            <IconText
                                icon={EmailOutlinedIcon}
                                text={
                                    candidate?.email ?? (
                                        <PlaceholderText
                                            returnOnlyText={true}
                                        />
                                    )
                                }
                            />
                            <IconText
                                icon={LocalPhoneOutlinedIcon}
                                text={composePhone}
                            />
                            <IconText
                                icon={CakeOutlinedIcon}
                                text={
                                    candidate?.birthdate ?? (
                                        <PlaceholderText
                                            returnOnlyText={true}
                                        />
                                    )
                                }
                            />
                            <IconText
                                icon={MilitaryTechOutlinedIcon}
                                text={composeMilitary()}
                            />
                        </Stack>
                        <Stack direction="row" component="ul" spacing={2}>
                            {candidate?.linkedin ? (
                                <IconBtn
                                    href={`${LINKEDIN_PREFIX}${candidate.linkedin}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    icon={LinkedinFilled}
                                    iconStyles={{ fontSize: ".8rem" }}
                                ></IconBtn>
                            ) : null}

                            {candidate?.collegelink ? (
                                <IconBtn
                                    href={`${COLLEGELINK_PREFIX}${candidate.collegelink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    icon={CollegelinkFilled}
                                    iconStyles={{ fontSize: "1rem" }}
                                ></IconBtn>
                            ) : null}
                        </Stack>
                    </Box>
                </Box>

                {/* Edit Top Section Popup */}
                <TopEditDialog
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    title="Edit top section information"
                ></TopEditDialog>
            </Box>
        </Box>
    );
};

export default TopSection;
