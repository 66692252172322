import { useState } from "react";
import { Box } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { useUpdateCandidateById } from "components/hooks/useUpdateCandidateById";
import CircularProgress from "@mui/material/CircularProgress";
import useNotifications from "components/hooks/useNotifications";
import getErrorMessage from "assets/data/errors.json";
import { useForm } from "react-hook-form";
import GeneralUtilityService from "services/GeneralUtilityService";

/**
 * Renders an inline editable Select field with multiple options.
 *
 * @param {string} fieldName - The name attribute of the input field. Defaults to "name".
 * @param {Object[]} selected - Selected option(s) to be displayed when not in edit mode.
 * @param {Object[]} options - The available options for the Select field.
 * @param {boolean} multiple - If true, allows multiple selections.
 * @param {number} maxSelectInputs - The number of allowed selected items.
 *
 * @returns {JSX.Element} - A Select field component with the following features:
 * 1. Inline editing.
 * 2. Option to allow single or multiple selections.
 * 3. Automatically updates the candidate data upon form submission on blur.
 */

export const InlineFormSelect = ({
    fieldName,
    selected,
    options,
    multiple = false,
    maxSelectInputs,
}) => {
    const { handleSubmit } = useForm();
    const candidate = useSelector((state) => state.currentCandidate);
    const [defaultOption, setOption] = useState(
        selected ? (Array.isArray(selected) ? selected : [selected]) : null
    );
    const [tempValue, setTempValue] = useState(() => {
        if (defaultOption) {
            return defaultOption.map((each) => each?.id);
        }

        return multiple ? [] : null;
    });

    const [selectedValuesLength, setSelectedValuesLength] = useState(
        tempValue?.length
    );

    const { updateCandidateById, isLoading } = useUpdateCandidateById();
    const { renderErrorNotification } = useNotifications();

    const editModeCancel = () => {
        setTempValue(
            defaultOption ? defaultOption?.map((each) => each?.id) : ""
        );
    };

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            editModeCancel();
        }
    };

    const selectedOptions = tempValue?.map((valueId) => {
        const defaultOption = options.find((each) => each.id === valueId);
        return defaultOption;
    });

    const onSubmit = () => {
        const hasMoreInputsThanMax = selectedValuesLength > maxSelectInputs;
        if (hasMoreInputsThanMax) {
            renderErrorNotification(
                `${getErrorMessage.maximum_selections.required} ${maxSelectInputs}`
            );
            return;
        }
        if (!isLoading) {
            const updatedData = {
                [fieldName]: multiple ? tempValue : tempValue[0],
            };
            setOption(selectedOptions);
            updateCandidateById(candidate.id, updatedData);
        }
    };

    const selectedIds = selectedOptions?.map((item) => item.id);
    const optionIds = defaultOption?.map((item) => item.id);

    const handleDynamicSubmitForm = () => {
        if (!GeneralUtilityService.areArraysEqual(selectedIds, optionIds)) {
            handleSubmit(onSubmit)();
        }
    };

    const handleSelectChange = (event) => {
        const selectedValues = event.target.value;
        setSelectedValuesLength(selectedValues?.length);
        if (maxSelectInputs && selectedValues?.length > maxSelectInputs) {
            renderErrorNotification(
                `${getErrorMessage.maximum_selections.required} ${maxSelectInputs}`
            );
        }
        setTempValue(multiple ? selectedValues : [selectedValues]);
    };

    return (
        <Box>
            {isLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        right: "0",
                        transform: "translateX(calc(-100% - .5rem))",
                    }}
                >
                    <CircularProgress thickness={2} color="gray" size={20} />
                </Box>
            )}
            <Box>
                <Box
                    component="form"
                    sx={{ width: "100%", display: "flex" }}
                    onKeyDown={handleKeyDown}
                >
                    <FormControl fullWidth variant="standard">
                        <Select
                            color="focus"
                            fullWidth
                            multiple={multiple}
                            value={tempValue || ""}
                            onChange={handleSelectChange}
                            onBlur={handleDynamicSubmitForm}
                            disableUnderline
                            sx={{
                                "& .MuiSelect-select": {
                                    height: "max-content!important",
                                    maxHeight: "unset!important",
                                },
                            }}
                            renderValue={() => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                        position: "relative",
                                    }}
                                >
                                    {selectedOptions?.map((value) => (
                                        <Chip
                                            key={value.id}
                                            label={value.name}
                                        />
                                    ))}
                                </Box>
                            )}
                        >
                            {options.map((eachOption, index) => {
                                return (
                                    <MenuItem key={index} value={eachOption.id}>
                                        {eachOption.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};

export default InlineFormSelect;
