import GeneralUtilityService from "services/GeneralUtilityService";

class ParserService {
    /**
     * Transforms the flattened data into its original hierarchical structure.
     * The method expects the keys to be in the format: 'groupName_index_fieldName', such as 'educations_0_university_start_at'.
     * It will then transform the flattened data into its unflattened form.
     *
     * @param {object} flattenedData - The flattened data object with keys following the specified format.
     * @returns {object} Returns the unflattened data object, or logs an error and returns undefined if the provided key format is incorrect.
     */
    unflattenData(flattenedData) {
        const unflattened = {};

        for (const key in flattenedData) {
            // Split by underscores that are not enclosed in quotes
            const parts = key.split(/_(?=(?:[^"]*"[^"]*")*[^"]*$)/);

            const groupName = parts[0];
            const index = parseInt(parts[1], 10);
            const fieldName = parts.slice(2).join("_");
            let value = flattenedData[key];

            try {
                value = JSON.parse(value);
            } catch (e) {
                // If it's not a JSON object, try to remove quotes if present
                if (
                    typeof value === "string" &&
                    value.startsWith('"') &&
                    value.endsWith('"')
                ) {
                    value = value.substring(1, value.length - 1);
                }
            }

            if (fieldName === "grade" && typeof value === "number") {
                const numericValue = parseFloat(value);
                value = numericValue.toFixed(1);
            }

            if (!unflattened[groupName]) {
                unflattened[groupName] = [];
            }

            if (!unflattened[groupName][index]) {
                unflattened[groupName][index] = {};
            }

            unflattened[groupName][index][fieldName] = value;
        }

        // The backend API expects skills to be a single 'skills' key.
        // The value should be an array of integers, where each integer represents the ID of a skill.
        // Example: skills: [2, 8, 7, 3]
        function extractSkillIds(skills) {
            return skills.map((skill) => skill.id);
        }

        // The backend API expects a single 'sectors' key. The value should be an array of integers,
        // where each integer represents the ID of a primary sector or subsector.
        // Example: sectors: [1, 33, 45, 6, 8]
        function combineSectorsAndSubsectors(experiences) {
            if (experiences) {
                return experiences.map((exp) => {
                    const sectorsCombined = [];
                    const skills = [];

                    if (exp.primarySectors) {
                        sectorsCombined.push(...exp.primarySectors);
                    }
                    if (exp.subSectors) {
                        sectorsCombined.push(...exp.subSectors);
                    }

                    if (exp.skills) skills.push(...extractSkillIds(exp.skills));

                    const sectors = sectorsCombined.map((sector) => sector.id);
                    return {
                        ...exp,
                        sectors,
                        skills,
                    };
                });
            }
        }

        const newExperiences = combineSectorsAndSubsectors(
            unflattened.experiences
        );

        unflattened.experiences = newExperiences;

        return unflattened;
    }

    transformDataForBackendCompatibility(data) {
        let newData = { ...data };

        if (newData.educations) {
            newData.educations = newData.educations.map((education) => {
                let newEducation = { ...education };

                if (newEducation.start_at && newEducation.start_at.id) {
                    newEducation.start_at = newEducation.start_at.id;
                }
                if (newEducation.end_at && newEducation.end_at.id) {
                    newEducation.end_at = newEducation.end_at.id;
                }

                return newEducation;
            });
        }

        return newData;
    }

    /**
     * This function processes 'dynamic_' prefixed fields in a flattened data object, a functionality
     * tied to user interactions where they choose between a parsed value and a RAPP value. If a
     * user opts not to retain the parsed value, this function replaces the parsed data with values from
     * corresponding 'dynamic_' fields. It's utilized in contexts where user choices directly influence
     * data structure, enhancing flexibility in data handling.
     *
     * @param {Object} flattenedData - Contains data with potential 'dynamic_' overrides.
     * @returns {Object} Updated data with applied 'dynamic_' values.
     */
    applyDynamicFieldUpdates(flattenedData) {
        const dynamicUpdates = {};

        // Step 2: Identify Dynamic Fields
        for (const key in flattenedData) {
            if (key.startsWith("dynamic_")) {
                const originalKey = key.replace("dynamic_", "");
                dynamicUpdates[originalKey] = flattenedData[key];
            }
        }

        // Step 3: Apply the Overrides
        for (const key in dynamicUpdates) {
            flattenedData[key] = dynamicUpdates[key];
        }

        // Step 4: Remove Dynamic Fields (Optional)
        for (const key in flattenedData) {
            if (key.startsWith("dynamic_")) {
                delete flattenedData[key];
            }
        }

        return flattenedData;
    }

    /**
     * Extracts a value from the provided hierarchical data based on a flattened key.
     * The key is expected to be in the format: groupName_index_fieldName (where fieldName can be a composite name),
     * such as 'experiences_0_start_at'. It returns the corresponding value from the nested data structure.
     *
     * @param {object} data - The hierarchical data structure.
     * @param {string} key - The flattened key to extract data from.
     * @returns {any} Returns the extracted value from the data based on the key.
     * @throws Will throw an error if the data or key format is unexpected.
     */
    extractParsedValueFromFlatKey(data, key) {
        // If the key is a single word (e.g., "firstName"), return its value directly
        if (!key.includes("_")) {
            if (key in data) {
                return data[key];
            } else if (data.generalInfo && key in data.generalInfo) {
                return data.generalInfo[key];
            } else {
                console.error(`Key ${key} not found in the provided data.`);
                throw new Error(`Key ${key} not found in the provided data.`);
            }
        }

        const parts = key.split(/_(?=(?:[^"]*"[^"]*")*[^"]*$)/);
        const groupName = parts[0];
        const index = parseInt(parts[1], 10);
        const fieldName = parts.slice(2).join("_");
        const existingDataIndex = index - data[groupName].length;
        const isValueFromExistingData = data[groupName].length < index + 1;
        const extractedParsedValue = isValueFromExistingData
            ? data.existingUserData[groupName][existingDataIndex][fieldName]
            : data[groupName][index][fieldName];

        if (
            !data[groupName] ||
            !data[groupName][index] ||
            !(fieldName in data[groupName][index])
        ) {
            if (!isValueFromExistingData) {
                console.error(
                    `Unexpected data or key format. Cannot find ${key} in the provided data.`
                );
                throw new Error(
                    `Unexpected data or key format. Cannot find ${key} in the provided data.`
                );
            }
        }

        if (
            !data.existingUserData[groupName] ||
            !data.existingUserData[groupName][existingDataIndex] ||
            !(fieldName in data.existingUserData[groupName][existingDataIndex])
        ) {
            if (isValueFromExistingData) {
                console.error(
                    `Unexpected data or key format. Cannot find ${key} in the provided data.`
                );
                throw new Error(
                    `Unexpected data or key format. Cannot find ${key} in the provided data.`
                );
            }
        }

        return extractedParsedValue ?? "";
    }

    normalizeData(data) {
        // Function to normalize experience data
        const normalizeExperience = (experience) => {
            const primarySectors =
                GeneralUtilityService.filterOutPrimarySectors(
                    experience.sectors
                );
            const subSectors = GeneralUtilityService.filterOutSubSectors(
                experience.sectors
            );
            return { ...experience, primarySectors, subSectors };
        };

        // Deep clone the original data
        const modifiedData = JSON.parse(JSON.stringify(data));

        // Normalize experiences
        if (Array.isArray(modifiedData.experiences)) {
            modifiedData.experiences = modifiedData.experiences.map((exp) =>
                normalizeExperience(exp)
            );
        }

        // Normalize existing experiences
        if (
            modifiedData.existingUserData &&
            Array.isArray(modifiedData.existingUserData.experiences)
        ) {
            modifiedData.existingUserData.experiences =
                modifiedData.existingUserData.experiences.map((exp) =>
                    normalizeExperience(exp)
                );
        }

        return modifiedData;
    }

    /**
     * Adds a similarity key when 2 resources match
     * @param {Array} parsedEntities - The parsed experiences array
     * @param {Array} rappEntities - The existing experiences array
     * @param {Function} matchFn - The function that adds the matching creteria
     */

    addSimilarityToMatchingResources(parsedEntities, rappEntities = []) {
        const copiedParsedEntities = [...parsedEntities];

        const copiedRappEntities = [...rappEntities];

        copiedParsedEntities.forEach(
            (entity) => (entity.shouldBePaired = false)
        );
        copiedRappEntities.forEach((entity) => (entity.shouldBePaired = false));

        copiedParsedEntities.forEach((parsedEntity) => {
            copiedRappEntities.forEach((rappEntity) => {
                // Convert start_at to a number if it's not already
                parsedEntity.start_at =
                    GeneralUtilityService.convertToNumberOrLeaveUnchanged(
                        parsedEntity.start_at
                    );
                rappEntity.start_at =
                    GeneralUtilityService.convertToNumberOrLeaveUnchanged(
                        rappEntity.start_at
                    );

                if (
                    parsedEntity.start_at === rappEntity.start_at &&
                    !parsedEntity.shouldBePaired &&
                    !rappEntity.shouldBePaired
                ) {
                    parsedEntity.similarity = rappEntity.id;
                    rappEntity.similarity = rappEntity.id;
                    parsedEntity.shouldBePaired = true;
                    rappEntity.shouldBePaired = true;
                }
            });
        });

        return { copiedParsedEntities, copiedRappEntities };
    }

    createCuratedStructure(rappData, parsedData) {
        let cutatedStructure = [];

        rappData?.forEach((rappItem) => {
            if (rappItem.shouldBePaired) {
                const matchedParsedItem = parsedData.find(
                    (parsedItem) =>
                        parsedItem.similarity === rappItem.similarity
                );
                cutatedStructure.push([rappItem, matchedParsedItem || {}]);
            } else {
                cutatedStructure.push([rappItem, {}]);
            }
        });

        parsedData?.forEach((parsedItem) => {
            if (!parsedItem.shouldBePaired) {
                cutatedStructure.push([{}, parsedItem]);
            }
        });

        return cutatedStructure;
    }
}

const service = new ParserService();
export default service;
