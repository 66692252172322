import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
    Box,
    Stack,
    Chip,
    List,
    ListItem,
    IconButton,
    Collapse,
    Typography,
} from "@mui/material";
import {
    ExtensionOutlined,
    StarBorderOutlined,
    CheckOutlined,
    LightbulbOutlined,
    FactCheckOutlined,
    Circle,
    KeyboardArrowUpOutlined,
    KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import IconContent from "components/lib-ui/IconContent";
import ShowMore from "./ShowMore";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

export const SectorExperienceTree = ({ data }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const theme = useTheme();
    const lineColor = theme.palette.gray.dark;
    const uncategorized = data.uncategorized;

    const {
        sectorName,
        yearsOfExperience,
        seniority,
        topResponsibilities,
        topSkills,
    } = data || {};

    const styles = {
        parent: {
            position: "relative",
            paddingLeft: "8px",
        },
        iconStyles: {
            border: `2px solid ${lineColor}`,
            borderRadius: "100%",
            fontSize: "1.1rem",
        },
    };
    const hasTopResponsibilites = Boolean(topResponsibilities?.length);
    const hasTopSkills = Boolean(topSkills?.length);
    const hasUncategorizedResponsibilities = Boolean(
        data?.uncategorized?.topResponsibilities?.length
    );
    const hasUncategorizedSkills = Boolean(
        data?.uncategorized?.topSkills?.length
    );

    const handleCollapse = () => {
        setIsCollapsed((collapse) => !collapse);
    };

    if (!uncategorized?.yearsOfExperience && !sectorName) return "";

    const categorisedSkills = data?.topSkills;
    const uncategorisedSkills = data?.uncategorized?.topSkills;
    const categorisedResponsibilities = data?.topResponsibilities;
    const uncategorisedResponsibilities =
        data?.uncategorized?.topResponsibilities;

    return (
        <Box component="ul">
            <Stack
                onClick={handleCollapse}
                sx={{ cursor: "pointer" }}
                direction="row"
                justifyContent="space-between"
            >
                <IconContent tooltipText={"Sector"} icon={ExtensionOutlined}>
                    <Chip
                        size="large"
                        color={"primary"}
                        label={uncategorized ? "Uncategorised" : sectorName}
                        sx={{
                            padding: "15px 10px",
                            background: uncategorized
                                ? theme.palette.gray.dark
                                : "inherited",
                        }}
                    />
                </IconContent>
                <IconButton>
                    {isCollapsed ? (
                        <KeyboardArrowUpOutlined />
                    ) : (
                        <KeyboardArrowDownOutlined />
                    )}
                </IconButton>
            </Stack>

            <Collapse in={isCollapsed}>
                <Stack pt={2.5} gap="20px">
                    <Stack
                        direction="row"
                        sx={{ ...styles.parent, background: "in" }}
                    >
                        <Box sx={styles.TShape} />
                        <IconContent
                            icon={SubdirectoryArrowRightIcon}
                            sx={{ pr: 2 }}
                        />
                        <IconContent
                            tooltipText={"Years of experience in this sector"}
                            text={
                                uncategorized
                                    ? data.uncategorized?.yearsOfExperience
                                    : yearsOfExperience
                            }
                            iconStyles={styles.iconStyles}
                            icon={StarBorderOutlined}
                        />
                    </Stack>

                    <>
                        {hasTopResponsibilites || hasTopSkills ? (
                            <Stack direction="row" sx={{ ...styles.parent }}>
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor:
                                            !hasTopResponsibilites &&
                                            !hasTopSkills &&
                                            "white",
                                    }}
                                />

                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={"Seniority"}
                                    text={seniority}
                                    iconStyles={styles.iconStyles}
                                    icon={LightbulbOutlined}
                                />
                            </Stack>
                        ) : null}

                        {hasTopSkills && (
                            <Stack
                                direction="row"
                                sx={{
                                    ...styles.parent,
                                }}
                            >
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor:
                                            !hasTopResponsibilites && "white",
                                    }}
                                />
                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={"Top hard skills"}
                                    iconStyles={styles.iconStyles}
                                    icon={CheckOutlined}
                                >
                                    <ShowMore
                                        items={categorisedSkills}
                                        initialCount={5}
                                        containerProps={{
                                            display: "flex",
                                            gap: "10px",
                                            flexWrap: "wrap",
                                        }}
                                        render={(skill) => (
                                            <Chip
                                                key={skill.id}
                                                label={skill.name}
                                            />
                                        )}
                                    />
                                </IconContent>
                            </Stack>
                        )}
                        {hasUncategorizedSkills && (
                            <Stack
                                direction="row"
                                sx={{
                                    ...styles.parent,
                                }}
                            >
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor: lineColor,
                                    }}
                                />
                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={"Uncategorised hard skills"}
                                    iconStyles={styles.iconStyles}
                                    icon={CheckOutlined}
                                >
                                    <ShowMore
                                        items={uncategorisedSkills}
                                        initialCount={5}
                                        containerProps={{
                                            display: "flex",
                                            gap: "10px",
                                            flexWrap: "wrap",
                                        }}
                                        render={(skill) => (
                                            <Chip
                                                key={skill.id}
                                                label={skill.name}
                                            />
                                        )}
                                    />
                                </IconContent>
                            </Stack>
                        )}

                        {hasTopResponsibilites && (
                            <Stack
                                direction="row"
                                sx={{
                                    ...styles.parent,
                                }}
                            >
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor: "white",
                                    }}
                                />
                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={"Top responsibilities"}
                                    iconStyles={{
                                        fontSize: "1rem",
                                        marginLeft: "-1px",
                                    }}
                                    icon={FactCheckOutlined}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Stack
                                        sx={{ maxWidth: "100%" }}
                                        direction="row"
                                        flexWrap="wrap"
                                        gap={1}
                                    >
                                        <List
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <ShowMore
                                                items={
                                                    categorisedResponsibilities
                                                }
                                                initialCount={5}
                                                buttonStyles={{
                                                    mt: "10px",
                                                }}
                                                render={(res) => (
                                                    <ListItem
                                                        key={res.id}
                                                        disableGutters
                                                        disablePadding
                                                    >
                                                        <Circle
                                                            sx={{
                                                                mr: "10px",
                                                                color: theme
                                                                    .palette
                                                                    .gray.soft,
                                                                fontSize:
                                                                    ".4rem",
                                                            }}
                                                        />
                                                        <Typography
                                                            fontSize=".9rem"
                                                            color="gray.dark"
                                                        >
                                                            {res.name}
                                                        </Typography>
                                                    </ListItem>
                                                )}
                                            />
                                        </List>
                                    </Stack>
                                </IconContent>
                            </Stack>
                        )}
                        {hasUncategorizedResponsibilities && (
                            <Stack
                                direction="row"
                                sx={{
                                    ...styles.parent,
                                }}
                            >
                                <Box
                                    sx={{
                                        ...styles.TShape,
                                        borderColor: "white",
                                    }}
                                />
                                <IconContent
                                    icon={SubdirectoryArrowRightIcon}
                                    sx={{ pr: 2 }}
                                />
                                <IconContent
                                    tooltipText={
                                        "Uncategorised responsibilities"
                                    }
                                    iconStyles={{
                                        fontSize: "1rem",
                                        marginLeft: "-1px",
                                    }}
                                    icon={FactCheckOutlined}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Stack
                                        sx={{ maxWidth: "100%" }}
                                        direction="row"
                                        flexWrap="wrap"
                                        gap={1}
                                    >
                                        <List
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <ShowMore
                                                items={
                                                    uncategorisedResponsibilities
                                                }
                                                initialCount={5}
                                                buttonStyles={{
                                                    mt: "10px",
                                                }}
                                                render={(res) => (
                                                    <ListItem
                                                        key={res.id}
                                                        disableGutters
                                                        disablePadding
                                                    >
                                                        <Circle
                                                            sx={{
                                                                mr: "10px",
                                                                color: theme
                                                                    .palette
                                                                    .gray.soft,
                                                                fontSize:
                                                                    ".4rem",
                                                            }}
                                                        />
                                                        <Typography
                                                            fontSize=".9rem"
                                                            color="gray.dark"
                                                        >
                                                            {res.name}
                                                        </Typography>
                                                    </ListItem>
                                                )}
                                            />
                                        </List>
                                    </Stack>
                                </IconContent>
                            </Stack>
                        )}
                    </>
                </Stack>
            </Collapse>
        </Box>
    );
};

export default SectorExperienceTree;
