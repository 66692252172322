import { EnvService } from "../envs";

class URLService {
    GLOBAL_APP_DATA_PATH =
        EnvService.getBackEndBaseUrl() + "/api/global-application-data";
    CANDIDATES_API_PATH = EnvService.getBackEndBaseUrl() + "/api/candidates";
    VALIDATE_CANDIDATE_PATH =
        EnvService.getBackEndBaseUrl() + "/api/validator/candidate";
    CANDIDATE_CV_PATH =
        EnvService.getBackEndBaseUrl() + "/api/storage/cv?candidateId=";
    CANDIDATE_CV_UPLOAD_PATH =
        EnvService.getBackEndBaseUrl() + "/api/storage/cv";
    RECRUITERS_ACCOUNTS_PATH =
        EnvService.getBackEndBaseUrl() + "/api/recruiters";
    HFR_JOBS_PATH = EnvService.getBackEndBaseUrl() + "/api/hfr-jobs";
    CANDIDATE_PROFILE_PATH = "/candidate/view";
    CANDIDATE_COLLEGELINK_PATH = EnvService.getCollegeLinkBaseUrl();
    CANDIDATE_ADD_RECRUITMENT_PROCESS_PATH =
        EnvService.getBackEndBaseUrl() + "/api/recruitment-processes/new";
    CANDIDATE_RECRUITMENT_PROCESS_PATH =
        EnvService.getBackEndBaseUrl() + "/api/recruitment-processes";
    JOBS_COMPANIES_PATH =
        EnvService.getBackEndBaseUrl() + "/api/flow-jobs-companies";
    PARSE_CV_OR_PDF_PATH = EnvService.getBackEndBaseUrl() + "/api/cv-parse";
    CV_PARSE_PDF_PATH = EnvService.getBackEndBaseUrl() + "/api/cv-parse-pdf";
    CV_PARSE_LINKEDIN_PATH =
        EnvService.getBackEndBaseUrl() + "/api/cv-parse-linkedin";
    CV_PARSE_SUBMIT_PATH = EnvService.getBackEndBaseUrl() + "/api/cv-save";
    EXISTING_CANDIDATE_PARSE_PATH =
        EnvService.getBackEndBaseUrl() + "/api/cv-parse-user";
    CV_PARSE_SUBMIT_AND_UPDATE_PATH = "/cv-update";
    CANDIDATE_HISTORY_PATH =
    EnvService.getBackEndBaseUrl() + "/api/history/candidates";
}

const service = new URLService();
export default service;
