import React from "react";
import IconBtn from "../../../../components/lib-ui/IconBtn";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TopSectionForm from "features/candidate/components/candidate-form/TopSectionForm";

export const TopEditDialog = ({ open, handleClose, title }) => {
    return (
        <Dialog fullWidth open={open} onClose={handleClose}>
            {title ? (
                <DialogTitle
                    sx={{ border: "1px solid", borderColor: "gray.soft" }}
                >
                    <Typography variant="title2">{title} </Typography>
                </DialogTitle>
            ) : null}

            <DialogContent>
                {/* Pass the handleClose function as onSubmit prop */}
                <TopSectionForm onSubmit={handleClose}></TopSectionForm>
            </DialogContent>

            <IconBtn
                defaulted={true}
                sx={{
                    position: "absolute",
                    top: 3,
                    right: 3,
                    border: "unset",
                }}
                icon={CancelOutlinedIcon}
                onClick={handleClose}
                iconStyles={{ fontSize: "1.5rem" }}
            />
        </Dialog>
    );
};

export default TopEditDialog;
