import axios from "utils/axiosConfig";
import { useState, useEffect } from "react";
import { EnvService } from "infra/envs/";

export const useFetchCompanies = () => {
    const companiesPath = `${EnvService.getBackEndBaseUrl()}/api/companies`;
    const [fetching, setFetching] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            setFetching(true);
            try {
                const response = await axios.get(companiesPath);

                setCompaniesOptions(response.data);
                setRequestSuccessful(true);
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setFetching(false);
            }
        };
        fetchCompanies();
    }, [companiesPath]);

    return { fetching, requestSuccessful, companiesOptions };
};

export default useFetchCompanies;
