import React from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import mainTheme from "assets/theme/MainTheme";

export const EnvIndicator = ({ currentEnv }) => {
    const ENV_HOSTNAME = {
        LOCAL: "localhost",
        DEV: "rapp-dev.cl-team.xyz",
        TESTING: "rapp-test.cl-team.xyz",
    };

    const determineEnv = (environment) => {
        let backgroundColor = null;
        let label = null;

        switch (environment) {
            case ENV_HOSTNAME.LOCAL:
                backgroundColor = mainTheme.palette.violet.main;
                label = "LOCAL";
                break;
            case ENV_HOSTNAME.TESTING:
                backgroundColor = mainTheme.palette.orange.main;
                label = "TESTING";
                break;
            case ENV_HOSTNAME.DEV:
                backgroundColor = mainTheme.palette.greenCyan.main;
                label = "DEV";
                break;
            default:
                return null;
        }

        if (backgroundColor && label) {
            return (
                <Box
                    sx={{
                        height: "30px",
                        width: "100%",
                        display: "flex",
                        position: "sticky",
                        top: 0,
                        zIndex: 1000,
                        borderBottom: "1px solid #E0E0E0",
                        backgroundColor: backgroundColor,
                    }}
                >
                    <Typography
                        component="h3"
                        variant="subtitle1"
                        align="left"
                        sx={{ fontWeight: 600, color: "primary.main" }}
                        mx={2}
                    >
                        {label}
                    </Typography>
                </Box>
            );
        }

        return null;
    };

    return (
        <ThemeProvider theme={mainTheme}>
            {determineEnv(currentEnv)}
        </ThemeProvider>
    );
};

export default EnvIndicator;
