import React from "react";
import CandidatesList from "../../features/candidate/pages/CandidatesList";
import { Login } from "../../features/login";
import CandidateAdd from "../../features/candidate/pages/CandidateAdd";
import CandidateView from "../../features/candidate/pages/CandidateView";
import CandidateParse from "../../features/candidate/pages/CandidateParse";

export const routes = [
    {
        path: "/login",
        withLayout: false,
        withAuth: false,
        element: <Login />,
    },
    {
        path: "/",
        withLayout: true,
        withAuth: true,
        element: <CandidatesList />,
    },
    {
        path: "/candidate/add/manual-fill",
        withLayout: true,
        withAuth: true,
        element: <CandidateAdd />,
    },
    {
        path: "/candidate/add/parse-linkedin",
        withLayout: true,
        withAuth: true,
        element: <CandidateParse />,
    },
    {
        path: "/candidate/add/parse-cv",
        withLayout: true,
        withAuth: true,
        element: <CandidateParse />,
    },
    {
        path: "/candidate/view/:id",
        withLayout: true,
        withAuth: true,
        element: <CandidateView />,
    },
];
