import axios from "utils/axiosConfig";
import { useState } from "react";
import { urlService } from "infra/envs/";
import useNotifications from "components/hooks/useNotifications";
import Utils from "services/GeneralUtilityService";
import { useDispatch } from "react-redux";
import {
    setEducationsStart,
    setEducations,
    addEducation,
    updateEducation,
} from "reducers/educationsSlice";
import { updateCandidateVersion } from "reducers/currentCandidateSlice";

export const useEducation = () => {
    const [isEducationFetchLoading, setIsEducationFetchLoading] =
        useState(false);
    const [isEducationUpdateLoading, setIsEducationUpdateLoading] =
        useState(false);
    const [isEducationCreateLoading, setIsEducationCreateLoading] =
        useState(false);
    const [isEducationDeleteLoading, setIsEducationDeleteLoading] =
        useState(false);

    const [
        educationFetchRequestSuccessful,
        setEducationFetchRequestSuccessful,
    ] = useState(false);
    const [
        educationUpdateRequestSuccessful,
        setEducationUpdateRequestSuccessful,
    ] = useState(false);
    const [
        educationCreateRequestSuccessful,
        setEducationCreateRequestSuccessful,
    ] = useState(false);
    const [
        educationDeleteRequestSuccessful,
        setEducationDeleteRequestSuccessful,
    ] = useState(false);

    const { renderNotification, renderErrorNotification } = useNotifications();
    const dispatch = useDispatch();

    const getEducations = async (id) => {
        setIsEducationFetchLoading(true);
        dispatch(setEducationsStart());
        try {
            const response = await axios.get(
                `${urlService.CANDIDATES_API_PATH}/${id}/educations`
            );

            const candidateEducations = response.data;
            dispatch(setEducations(candidateEducations));
            setEducationFetchRequestSuccessful(true);
            setIsEducationFetchLoading(false);
            return candidateEducations;
        } catch (error) {
            const errorMessage = Utils.extractErrorMessage(error.response.data);
            setEducationFetchRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsEducationFetchLoading(false);
        }
    };

    const updateEducationById = async (endpointParams, updateData) => {
        setIsEducationUpdateLoading(true);
        const { id, candidate_id } = endpointParams;
        const endpoint = `${urlService.CANDIDATES_API_PATH}/${candidate_id}/educations/${id}`;
        try {
            const response = await axios.patch(endpoint, updateData);

            dispatch(updateEducation(response.data));
            dispatch(updateCandidateVersion())
            setEducationUpdateRequestSuccessful(true);
            setIsEducationUpdateLoading(false);
            renderNotification(`Education successfully updated`);
        } catch (error) {
            const errorMessage = Utils.extractErrorMessage(error.response.data);
            setEducationUpdateRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsEducationUpdateLoading(false);
        }
    };

    const createEducation = async (candidateId, createData) => {
        setIsEducationCreateLoading(true);
        const endpoint = `${urlService.CANDIDATES_API_PATH}/${candidateId}/educations`;
        try {
            const response = await axios.post(endpoint, createData);

            dispatch(addEducation(response.data));
            dispatch(updateCandidateVersion())
            setEducationCreateRequestSuccessful(true);
            setIsEducationCreateLoading(false);
            renderNotification(`Education successfully created`);
        } catch (error) {
            const errorMessage = Utils.extractErrorMessage(error.response.data);
            setEducationCreateRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsEducationCreateLoading(false);
        }
    };

    const deleteEducation = async (endpointParams, updateData) => {
        setIsEducationDeleteLoading(true);
        const { id, candidate_id } = endpointParams;
        const endpoint = `${urlService.CANDIDATES_API_PATH}/${candidate_id}/educations/${id}`;
        try {
            const response = await axios.delete(endpoint);

            const candidateEducations = response.data;
            dispatch(updateEducation(candidateEducations));
            dispatch(updateCandidateVersion())
            setEducationDeleteRequestSuccessful(true);
            setIsEducationDeleteLoading(false);
            renderNotification(`Education successfully deleted`);
        } catch (error) {
            const errorMessage = Utils.extractErrorMessage(error.response.data);
            setEducationDeleteRequestSuccessful(false);
            renderErrorNotification(errorMessage);
        } finally {
            setIsEducationDeleteLoading(false);
        }
    };

    return {
        getEducations,
        updateEducationById,
        createEducation,
        deleteEducation,
        isEducationFetchLoading,
        isEducationUpdateLoading,
        isEducationDeleteLoading,
        isEducationCreateLoading,
        educationFetchRequestSuccessful,
        educationUpdateRequestSuccessful,
        educationCreateRequestSuccessful,
        educationDeleteRequestSuccessful,
    };
};
