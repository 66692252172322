import {
    Box,
    Typography,
    Autocomplete,
    FormControlLabel,
    Switch,
    TextField,
    Stack,
    Button,
} from "@mui/material";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import { useForm, Controller } from "react-hook-form";
import { GlobalAppDataStorageService } from "infra/storage";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useState, useEffect } from "react";
import getErrorMessage from "assets/data/errors.json";
import { useEducation } from "components/hooks/useEducation";
import Utils from "services/GeneralUtilityService";
import useFetchUniversitiesAndDepartments from "components/hooks/useFetchUniversitiesAndDepartments";
import useInsertUniversity from "components/hooks/useInsertUniversity";
import MeteredButton from "components/lib-ui/MeteredButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import useInsertDepartment from "components/hooks/useInsertDepartment";

export const EducationForm = ({
    title,
    setIsDialogOpen,
    isEditMode = false,
    educationData,
    onSubmit,
}) => {
    const {
        handleSubmit,
        control,
        register,
        watch,
        formState: { errors },
        setValue,
    } = useForm();

    const {
        updateEducationById,
        createEducation,
        deleteEducation,
        isEducationUpdateLoading,
        isEducationDeleteLoading,
        isEducationCreateLoading,
        educationUpdateRequestSuccessful,
        educationCreateRequestSuccessful,
        educationDeleteRequestSuccessful,
    } = useEducation();

    const {
        university,
        educationLevel,
        department,
        grade,
        start_at,
        end_at,
        academic_fields,
    } = educationData || {};

    const startAtOption = start_at
        ? {
              id: start_at,
              name: start_at?.toString(),
          }
        : null;

    const endAtOption = end_at
        ? {
              id: end_at,
              name: end_at?.toString(),
          }
        : null;

    const candidateId = useSelector((state) => state.currentCandidate.id);

    const educationLevelOptions =
        GlobalAppDataStorageService.getEducationLevels();

    const academicFieldsOptions =
        GlobalAppDataStorageService.getAcademicFields();

    const yearOptions = Utils.getYearOptions(50);

    const [currentlySelected, setCurrentlySelected] = useState(false);

    // ToDo Check how the 2 following useEffects could be merged into 1
    useEffect(() => {
        if (start_at && !end_at) {
            setCurrentlySelected(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // when currently is selected, set end date as null
    useEffect(() => {
        if (currentlySelected) {
            setValue("endAt", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlySelected]);

    useEffect(() => {
        if (
            educationUpdateRequestSuccessful ||
            educationCreateRequestSuccessful ||
            educationDeleteRequestSuccessful
        ) {
            setIsDialogOpen(false);
        }
    }, [
        educationUpdateRequestSuccessful,
        educationCreateRequestSuccessful,
        educationDeleteRequestSuccessful,
        setIsDialogOpen,
    ]);

    const handleSwitch = (event) => {
        setCurrentlySelected(event.target.checked);
    };

    const today = new Date();

    const currentYear = today.getFullYear();

    const defaultFocusOption = yearOptions.findIndex(
        (option) => option.id === currentYear
    );

    const watchStartDate = watch("startAt");

    const [isNewUnivesity, setIsNewUniversity] = useState(false);

    const toggleNewUniversityVisibility = () => {
        setIsNewUniversity(!isNewUnivesity);
    };

    const handleNewUniversityClick = (event) => {
        toggleNewUniversityVisibility();
        event.target.focus();
    };

    const [isNewDepartment, setIsNewDepartment] = useState(false);

    const toggleNewDepartmentVisibility = () => {
        setIsNewDepartment(!isNewDepartment);
    };

    const handleNewDepartmentClick = (event) => {
        toggleNewDepartmentVisibility();
        event.target.focus();
    };

    const {
        isLoadingUniversities,
        universitiesOptions,
        isLoadingDepartments,
        departmentsOptions,
    } = useFetchUniversitiesAndDepartments();

    const { insertUniversity, isLoadingInsertUniversity } =
        useInsertUniversity();

    const { insertDepartment, isLoadingInsertDepartment } =
        useInsertDepartment();

    const handleDeleteEducation = (event) => {
        deleteEducation(educationData);
    };

    const getStartAtErrorMessage = () => {
        if (errors?.startAt?.type === "required")
            return getErrorMessage.date_range.start_date.required;
    };

    const getEndAtErrorMessage = () => {
        if (errors?.endAt?.type === "required") {
            return getErrorMessage.date_range.end_date.required;
        }
        if (errors?.endAt?.type === "validate") {
            return getErrorMessage.date_range.end_date.wrong_range;
        }
    };

    const submitForm = async (data) => {
        const newUniversityId = isNewUnivesity
            ? await insertUniversity(data)
            : null;

        const newDepartmentId = isNewDepartment
            ? await insertDepartment(data)
            : null;

        const dataIds = {
            universityId: newUniversityId
                ? newUniversityId
                : data?.university?.id,
            departmentId: newDepartmentId
                ? newDepartmentId
                : data?.department?.id ?? null,
            educationLevelId: data?.education_level?.id ?? null,
            grade: data.grade
                ? parseFloat(data.grade).toFixed(1)
                : parseFloat(0).toFixed(1),
            startAt: data?.startAt?.id,
            endAt: currentlySelected ? null : data?.endAt?.id,
            academicFields: data?.academicFields?.map((each) => each?.id) ?? [],
        };
        if (
            !isEducationUpdateLoading &&
            !isEducationDeleteLoading &&
            !isEducationCreateLoading
        ) {
            isEditMode
                ? updateEducationById(educationData, dataIds)
                : createEducation(candidateId, dataIds);
        }
        if (isEditMode) {
            onSubmit();
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Typography variant="header2">{title} </Typography>
            <Stack mt={3} spacing={3}>
                {isNewUnivesity ? (
                    <>
                        <Typography variant="header3">
                            Add a New University
                        </Typography>
                        <TextField
                            fullWidth
                            variant="standard"
                            name="new_university"
                            type="text"
                            {...register("new_university")}
                            label="University"
                        />
                        <Button onClick={handleNewUniversityClick}>
                            Go back to universities from database
                        </Button>
                    </>
                ) : null}
                {/* University - Education Level */}
                <HorizontalFlex>
                    <Controller
                        name="university"
                        control={control}
                        defaultValue={university || null}
                        rules={{
                            required: !isNewUnivesity,
                        }}
                        render={({ field }) => (
                            <Autocomplete
                                id="university"
                                fullWidth
                                autoComplete
                                loading={isLoadingUniversities}
                                loadingText="Loading"
                                disabled={isNewUnivesity}
                                options={universitiesOptions || []}
                                getOptionLabel={(option) => option?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                noOptionsText={
                                    <Button onClick={handleNewUniversityClick}>
                                        No match? Click to add university
                                    </Button>
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="University"
                                        error={Boolean(errors.university)}
                                        helperText={
                                            errors.university
                                                ? getErrorMessage.education
                                                      .university.required
                                                : ""
                                        }
                                    />
                                )}
                            />
                        )}
                    />

                    <Controller
                        name="education_level"
                        control={control}
                        defaultValue={educationLevel || null}
                        render={({ field }) => (
                            <Autocomplete
                                id="education_level"
                                fullWidth
                                autoComplete
                                options={educationLevelOptions || []}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Education Level"
                                    />
                                )}
                            />
                        )}
                    />
                </HorizontalFlex>
                {isNewDepartment ? (
                    <>
                        <Typography variant="header3">
                            Add a New Department
                        </Typography>
                        <TextField
                            fullWidth
                            variant="standard"
                            name="new_department"
                            type="text"
                            {...register("new_department")}
                            label="Department"
                        />
                        <Button onClick={handleNewDepartmentClick}>
                            Go back to departments from database
                        </Button>
                    </>
                ) : null}
                {/* Department - Grade */}
                <HorizontalFlex>
                    <Controller
                        name="department"
                        control={control}
                        defaultValue={department || null}
                        render={({ field }) => (
                            <Autocomplete
                                id="department"
                                fullWidth
                                autoComplete
                                loading={isLoadingDepartments}
                                loadingText="Loading"
                                disabled={isNewDepartment}
                                options={departmentsOptions || []}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                noOptionsText={
                                    <Button onClick={handleNewDepartmentClick}>
                                        No match? Click to add department
                                    </Button>
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Department"
                                    />
                                )}
                            />
                        )}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        name="grade"
                        type="number"
                        defaultValue={grade || null}
                        {...register("grade", {
                            max: {
                                value: 10,
                                message:
                                    getErrorMessage.generic.range + " 1-10",
                            },
                            min: {
                                value: 1,
                                message:
                                    getErrorMessage.generic.range + " 1-10",
                            },
                        })}
                        error={Boolean(errors.grade)}
                        helperText={
                            errors.grade
                                ? getErrorMessage.generic.range + " 1-10"
                                : ""
                        }
                        label="Grade"
                    />
                </HorizontalFlex>
                {/* Start Date - End Date */}
                <HorizontalFlex>
                    <Controller
                        name="startAt"
                        control={control}
                        defaultValue={startAtOption || null}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <Autocomplete
                                id="startAt"
                                fullWidth
                                autoComplete
                                options={yearOptions || []}
                                value={field.id}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) => {
                                    return option.id === value?.id;
                                }}
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Start Date"
                                        error={!!errors.startAt}
                                        helperText={getStartAtErrorMessage()}
                                    />
                                )}
                                onFocus={(event) => {
                                    if (event.target.value) return;
                                    field.onChange(
                                        yearOptions[defaultFocusOption]
                                    );
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="endAt"
                        control={control}
                        defaultValue={endAtOption || null}
                        rules={{
                            validate: (value) => {
                                const startDate = watchStartDate?.id;
                                if (value?.id < startDate) return false;
                                return true;
                            },
                            required: !currentlySelected,
                        }}
                        render={({ field }) => (
                            <Autocomplete
                                id="endAt"
                                fullWidth
                                autoComplete
                                disabled={currentlySelected}
                                options={yearOptions || []}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value?.id
                                }
                                {...field}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="End Date"
                                        error={!!errors.endAt}
                                        helperText={getEndAtErrorMessage()}
                                    />
                                )}
                                onFocus={(event) => {
                                    if (event.target.value) return;
                                    field.onChange(
                                        yearOptions[defaultFocusOption]
                                    );
                                }}
                            />
                        )}
                    />
                </HorizontalFlex>
                <HorizontalFlex>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={currentlySelected}
                                onChange={handleSwitch}
                            />
                        }
                        label="Currently studying there"
                    />
                </HorizontalFlex>
                {/* Education Sector */}
                <Controller
                    name="academicFields"
                    control={control}
                    defaultValue={academic_fields || []}
                    render={({ field }) => (
                        <Autocomplete
                            fullWidth
                            multiple
                            autoComplete
                            filterSelectedOptions
                            id="academicFields"
                            options={academicFieldsOptions || []}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            {...field}
                            onChange={(e, data) => {
                                field.onChange(data);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Academic Fields"
                                />
                            )}
                        />
                    )}
                />
                <Stack direction="row" justifyContent="space-between">
                    {isEditMode && (
                        <MeteredButton
                            onActionComplete={handleDeleteEducation}
                            disabled={
                                isEducationUpdateLoading ||
                                isEducationCreateLoading
                            }
                            loading={isEducationDeleteLoading}
                            buttonProps={{
                                color: "gray",
                                startIcon: <DeleteOutlinedIcon />,
                            }}
                        >
                            Delete
                        </MeteredButton>
                    )}

                    <LoadingButton
                        sx={{ marginLeft: "auto" }}
                        size="medium"
                        color="primary"
                        type="submit"
                        loading={
                            isEducationUpdateLoading ||
                            isEducationCreateLoading ||
                            isLoadingInsertUniversity ||
                            isLoadingInsertDepartment
                        }
                        disabled={
                            isEducationUpdateLoading ||
                            isEducationCreateLoading ||
                            isEducationDeleteLoading ||
                            isLoadingInsertUniversity ||
                            isLoadingInsertDepartment
                        }
                        variant="text"
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                    >
                        Save Changes
                    </LoadingButton>
                </Stack>
            </Stack>
        </Box>
    );
};

export default EducationForm;
