import { createSlice } from "@reduxjs/toolkit";
import GeneralUtilityService from "services/GeneralUtilityService";

const initialState = {
    sectors: [],
    subsectors: [],
    pairs: [], // Each pair will have its own selectedSector, availableSubsectors, and selectedSubsectors
};

const sectorSlice = createSlice({
    name: "sector",
    initialState,
    reducers: {
        initialize: (state, action) => {
            state.sectors = action.payload.primary;
            state.subsectors = action.payload.subsectors;
        },
        addOrUpdatePair: (state, action) => {

            if (!Array.isArray(state.pairs)) return; 
            
            const existingPairIndex = state.pairs.findIndex(
                (pair) => pair.id === action.payload.id
            );

            if (existingPairIndex !== -1) {
                // Update existing pair
                state.pairs[existingPairIndex] = {
                    ...state.pairs[existingPairIndex],
                    ...action.payload,
                };
            } else {
                // Add new pair
                state.pairs.push({
                    id: action.payload.id,
                    selectedSector: null,
                    availableSubsectors: [],
                    selectedSubsectors: [],
                    ...action.payload,
                });
            }
        },
        updatePair: (state, action) => {
            const { id } = action.payload;
            if (!state?.pairs?.[id]) return;

            let renewSelectedSubsectors = [];

            let currentSectorIds = new Set();
            if (Array.isArray(state.pairs[id].selectedSector)) {
                currentSectorIds = new Set(
                    state.pairs[id].selectedSector.map((sector) => sector.id)
                );

                if (Array.isArray(state.pairs[id].selectedSubsectors)) {
                    renewSelectedSubsectors = state.pairs[
                        id
                    ].selectedSubsectors.filter((eachSelectedSub) => {
                        return currentSectorIds.has(
                            eachSelectedSub?.parent_sector?.id
                        );
                    });
                }
            }

            const availableSubsectors =
                GeneralUtilityService.filterSubsectorsByParentIds(
                    state.subsectors,
                    state.pairs[id].selectedSector
                );

            const newPair = {
                ...state.pairs[id],
                selectedSubsectors: [...renewSelectedSubsectors],
                availableSubsectors: [...availableSubsectors],
            };

            state.pairs[id] = newPair;
        },
        selectSubsector: (state, action) => {
            const { pairId, subsector } = action.payload;
            const pair = state.pairs.find((p) => p.id === pairId);
            if (pair && pair.selectedSector) {
                pair.selectedSubsectors.push(subsector);
            }
        },
    },
});

export const {
    initialize,
    addOrUpdatePair,
    selectSubsector,
    updatePair,
} = sectorSlice.actions;

export default sectorSlice.reducer;
