import { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import getErrorMessage from "assets/data/errors.json";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import { useAddRecuitmentProcess } from "components/hooks/useRecruitmentProcess";

export const AddNewRecuitmentProcess = ({ title, setIsDialogOpen }) => {
    const candidateId = useSelector((state) => state.currentCandidate.id);
    const recruitersOptions = useSelector(
        (state) => state.recruitersAccounts.data
    );

    const { addRecuitmentProcess, isLoading, requestSuccessful } =
        useAddRecuitmentProcess();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (requestSuccessful) {
            setIsDialogOpen(false);
        }
    }, [requestSuccessful, setIsDialogOpen]);

    const submitForm = async (data) => {
        if (!isLoading) {
            await addRecuitmentProcess(candidateId, data);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title}</Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
                <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                    <TextField
                        {...register("title", {
                            required: getErrorMessage.title.required,
                        })}
                        error={Boolean(errors.title)}
                        helperText={errors.title ? errors.title.message : ""}
                        fullWidth
                        name="title"
                        defaultValue={""}
                        label="Title"
                    />
                    <TextField
                        {...register("recruiterId", {
                            required:
                                getErrorMessage.recruiter_account.required,
                        })}
                        error={Boolean(errors.recruiter_account)}
                        helperText={
                            errors.recruiter_account
                                ? errors.recruiter_account.message
                                : ""
                        }
                        fullWidth
                        required
                        select
                        name="recruiterId"
                        defaultValue={""}
                        label="Linkedin Recruiters Account"
                    >
                        {recruitersOptions?.map((option) => {
                            // handle null, undefined, and empty fullName
                            const fullName =
                                (option.name || "") && (option.surname || "")
                                    ? `${option.name} ${option.surname}`
                                    : option.email;

                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    {fullName}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </HorizontalFlex>
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default AddNewRecuitmentProcess;
