import ParserAutoComplete from "./dynamicInputs/ParserAutoComplete";
import ParserTextField from "./dynamicInputs/ParserTextField";
import YearAutoComplete from "./dynamicInputs/YearAutoComplete";
import CityAutoComplete from "./dynamicInputs/CityAutoComplete";

export const DynamicFieldEditor = ({
    value,
    type,
    fieldName,
    formControls,
    options,
    disabled,
    required = false,
}) => {
    const commonProps = {
        disabled,
        value,
        fieldName,
        formControls,
    };

    switch (type) {
        case "city":
            return <CityAutoComplete {...commonProps} />;

        case "year":
            return (
                <YearAutoComplete
                    {...commonProps}
                    type={type}
                    multiple={false}
                    options={options}
                />
            );

        case "company":
            return (
                <ParserAutoComplete
                    {...commonProps}
                    type={type}
                    multiple={false}
                    freeSolo={type === "company"}
                    options={options}
                    required={required}
                />
            );
        case "autocomplete":
            return (
                <ParserAutoComplete
                    {...commonProps}
                    type={type}
                    multiple={false}
                    freeSolo={type === "company"}
                    options={options}
                />
            );

        case "university":
        case "department":
            return (
                <ParserAutoComplete
                    multiple={false}
                    freeSolo={true}
                    options={options}
                    {...commonProps}
                />
            );

        case "skills":
            return (
                <ParserAutoComplete
                    {...commonProps}
                    type={type}
                    multiple={true}
                    freeSolo={false}
                    options={options}
                />
            );
        case "sectors":
            return (
                <ParserAutoComplete
                    {...commonProps}
                    type={type}
                    multiple={true}
                    freeSolo={false}
                    options={options}
                />
            );
        case "subsectors":
            return (
                <ParserAutoComplete
                    {...commonProps}
                    type={type}
                    multiple={true}
                    freeSolo={false}
                    options={options}
                />
            );

        case "options":
            return null;

        case "number":
            return <ParserTextField {...commonProps} type={type} />;
        case "text":
        case "textarea":
        case "date":
            return <ParserTextField {...commonProps} type={type} />;

        default:
            return <ParserTextField {...commonProps} type="text" />;
    }
};

export default DynamicFieldEditor;
