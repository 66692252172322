import SectionCard from "features/candidate/components/candidate-view/SectionCard";
import SectionRow from "features/candidate/components/candidate-view/SectionRow";
import InlineFormSelect from "components/lib-ui/inline-forms/InlineFormSelect";
import InlineFormAutocomplete from "components/lib-ui/inline-forms/InlineFormAutocomplete";
import { GlobalAppDataStorageService } from "infra/storage";
import GeneralUtilityService from "services/GeneralUtilityService";

export const Personality = ({ candidate }) => {
    const softSkillsOptions = GlobalAppDataStorageService.getSoftSkills();
    const motivationOptions = GlobalAppDataStorageService.getMotivations();
    const attitudeOptions = GlobalAppDataStorageService.getAttitudes();

    const { attitude, motivation, softSkills, otherSkills } = candidate;

    return (
        <SectionCard title="Personality">
            <SectionRow title="Attitude">
                <InlineFormSelect
                    fieldName="attitude_id"
                    selected={GeneralUtilityService.findObjectInArray(
                        attitude,
                        attitudeOptions
                    )}
                    options={attitudeOptions}
                />
            </SectionRow>

            <SectionRow title="Motivation" helperText="max 3">
                <InlineFormSelect
                    multiple
                    fieldName="motivations"
                    options={motivationOptions}
                    selected={motivation}
                    maxSelectInputs={3}
                />
            </SectionRow>

            <SectionRow title="Soft skills">
                <InlineFormAutocomplete
                    fieldName="skills"
                    options={softSkillsOptions}
                    selected={softSkills}
                    extraUpdateData={otherSkills}
                    chipAttributes={{ sx: { padding: "10px 5px" } }}
                />
            </SectionRow>
        </SectionCard>
    );
};

export default Personality;
