import { useState } from "react";
import { deepOrange, green } from "@mui/material/colors";
import { Box, Typography, IconButton, Avatar, Tooltip } from "@mui/material";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import InfoIcon from "@mui/icons-material/Info";
import DialogBox from "components/lib-ui/DialogBox";
import IconContent from "components/lib-ui/IconContent";
import EditState from "features/candidate/components/candidate-form/recruitment-processes/EditState";
import Utils from "services/GeneralUtilityService";
import ReProCardService from "services/RecruitmentProccessCard.service";
import { useSelector } from "react-redux";

export const Steps = ({ steps }) => {
    const isHiddenInfoVisible = useSelector(
        (state) => state.hiddenInfoSlice.isHiddenInfoVisible
    );
    const warningHex = deepOrange[700];
    const successHex = green[500];
    const rpFlowInternalCategoryId =
        ReProCardService.RP_FLOW_INTERNAL_CATEGORY_ID;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [stepData, setstepData] = useState({});
    const isInternal = steps?.rpFlowCategory?.id === rpFlowInternalCategoryId;
    const hasExternal = steps?.hasExternal;
    const shortlistedId = 13;
    const { companyNameInitials, companyProfileImageUrl } = steps;

    return (
        <>
            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Edit step"
                floatCancel={true}
                showActionButtons={false}
            >
                <EditState
                    setIsDialogOpen={setIsDialogOpen}
                    stepData={stepData}
                />
            </DialogBox>

            {steps?.rpFlowSteps?.map((eachStep, index, statesArray) => {
                const isFirstStage = index === 0;
                const hasOnlyOneStage =
                    isFirstStage && statesArray.length === 1;
                const isLastStage = index + 1 === statesArray.length;
                const getFirstRpStepStatus = eachStep?.rpStepStatuses[0];
                const getJustification =
                    getFirstRpStepStatus?.rpStepJustification?.name;
                const shouldShowIcon =
                    (!isInternal && hasExternal && isLastStage) ||
                    (!hasExternal && isLastStage);

                const stageAlignment = () => {
                    if (isFirstStage) return "flex-start";
                    if (isLastStage) return "flex-end";
                    return "center";
                };

                const StatusIds = {
                    REJECTED: [7, 8, 9],
                    DROPPED: [10, 11, 12],
                    SHORTLISTED: [13],
                    HIRED: [14],
                };

                const stepStatusId = getFirstRpStepStatus?.rpStepStatus?.id;

                const handleIconOutlineColor = (statusId) => {
                    const outlineColors = [7, 8, 9, 10, 11, 12, 13, 14];
                    return outlineColors.includes(statusId) ? "white" : "";
                };

                const handleIconBackgroundColor = (statusId) => {
                    if (
                        StatusIds.REJECTED.includes(statusId) ||
                        StatusIds.DROPPED.includes(statusId)
                    ) {
                        return warningHex;
                    }

                    if (
                        StatusIds.SHORTLISTED.includes(statusId) ||
                        StatusIds.HIRED.includes(statusId)
                    ) {
                        return successHex; // green color
                    }

                    return "white";
                };

                const iconBackgroundColor =
                    handleIconBackgroundColor(stepStatusId);
                const iconOutlineColor = handleIconOutlineColor(stepStatusId);

                return (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "fit-content",
                            position: "relative",
                            alignItems: stageAlignment(),
                            zIndex: "0",
                        }}
                    >
                        {getFirstRpStepStatus.rpStepStatus.id ===
                            shortlistedId && (
                            <Box
                                sx={{
                                    width: "3px",
                                    height: "100px",
                                    background: "#0297CC",
                                    position: "absolute",
                                    borderRadius: "100px",
                                    top: "0",
                                    left: "calc(100% + 5px )",
                                    marginLeft: "10px",
                                }}
                            >
                                <Box
                                    mb={1}
                                    sx={{
                                        position: "absolute",
                                        left: "50%",
                                        transform: "translate(-50%, -100%)",
                                        top: 0,
                                    }}
                                >
                                    <Avatar
                                        alt="Company avatar"
                                        src={companyProfileImageUrl}
                                        loading="lazy"
                                        sx={{
                                            width: 35,
                                            height: 35,
                                            fontSize: ".9rem",
                                            backgroundColor:
                                                companyProfileImageUrl
                                                    ? "gray"
                                                    : Utils.stringToColor(
                                                          companyNameInitials
                                                      ),
                                            zIndex: 1,
                                        }}
                                    >
                                        {companyNameInitials}
                                    </Avatar>
                                </Box>
                            </Box>
                        )}
                        <Box
                            sx={{
                                position: "relative",
                            }}
                        >
                            {shouldShowIcon && (
                                <IconButton
                                    data-testid="recruitmentProcess/editStepStatus"
                                    onClick={() => {
                                        setstepData({
                                            ...eachStep,
                                            score: steps?.score,
                                            allSteps: { ...steps },
                                        });
                                        setIsDialogOpen(true);
                                    }}
                                    sx={{
                                        position: "absolute",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        top: "-35px",
                                    }}
                                >
                                    <BorderColorRoundedIcon
                                        sx={{
                                            fontSize: "1rem",
                                        }}
                                    />
                                </IconButton>
                            )}

                            <Tooltip
                                title={getJustification}
                                followCursor={true}
                            >
                                <Box
                                    mb={1}
                                    sx={{
                                        boxShadow: (theme) => theme.shadows[3],
                                        background: iconBackgroundColor,
                                        border: "1px solid gray",
                                        width: "35px",
                                        aspectRatio: "1/1",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "100%",
                                        zIndex: "1",
                                        color: iconOutlineColor,
                                    }}
                                >
                                    {ReProCardService.getRecruitmentProcessStatusIcon(
                                        getFirstRpStepStatus?.rpStepStatus.name
                                    ) ||
                                        ReProCardService.getRecruitmentProcessStateIcon(
                                            eachStep?.rpStepState?.name
                                        )}
                                </Box>
                            </Tooltip>
                        </Box>
                        {hasOnlyOneStage ? (
                            ""
                        ) : (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "calc(35px / 2)",
                                    left: "30px",
                                    width: `calc( (100% - 30px ) + ${
                                        isLastStage ? "0rem" : "4rem"
                                    } )`,
                                    zIndex: "-1",
                                    borderBottom: "1px dashed #0297CB",
                                }}
                            />
                        )}
                        <Typography color="gray.dark" fontSize=".9rem">
                            {eachStep?.updatedAt
                                ? Utils.getTimeDifferenceString(
                                      eachStep?.rpStepStatuses[0]?.updatedAt
                                  )
                                : "-"}
                        </Typography>
                        <IconContent
                            icon={isHiddenInfoVisible ? InfoIcon : null}
                            tooltipText={`RP Step State Id: ${eachStep?.rpStepState?.id}`}
                            tooltipPlacement={"left"}
                        >
                            <Typography
                                fontSize=".9rem"
                                data-rp-step-state-id={
                                    eachStep?.rpStepState?.id
                                }
                            >
                                {eachStep?.rpStepState?.name}
                            </Typography>
                        </IconContent>
                        <IconContent
                            icon={isHiddenInfoVisible ? InfoIcon : null}
                            tooltipText={`RP Step Status Id: ${getFirstRpStepStatus?.rpStepStatus?.id}`}
                            tooltipPlacement={"left"}
                        >
                            <Typography
                                fontWeight={600}
                                fontSize=".9rem"
                                data-rp-step-status-id={
                                    getFirstRpStepStatus?.rpStepStatus?.id
                                }
                            >
                                {getFirstRpStepStatus?.rpStepStatus.name ||
                                    "Pending"}
                            </Typography>
                        </IconContent>
                    </Box>
                );
            })}
        </>
    );
};

export default Steps;
