import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const ParserAutoComplete = ({
    value,
    fieldName,
    required = false,
    multiple = true,
    freeSolo = false,
    disableClearable = true,
    disabled = false,
    options = [],
    formControls,
}) => {
    const { control,setValue } = formControls;

    const convertedValue = () => {
        if (multiple === true && !Array.isArray(value)) {
            return [];
        }
        return value;
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={convertedValue()}
            render={({ field }) => (
                <Autocomplete
                    fullWidth
                    id={fieldName}
                    multiple={multiple}
                    freeSolo={freeSolo}
                    disableClearable={disableClearable}
                    disabled={disabled}
                    autoComplete
                    options={options}
                    getOptionLabel={(option) =>
                        option?.name || option?.value || ""
                    }
                    isOptionEqualToValue={(option, value) => {
                        if (!value || value?.id === null) return;
                        return option.id === value.id;
                    }}
                    {...field}
                    onChange={(_, newValue) => {
                        field.onChange(newValue);
                        setValue(
                            fieldName,
                            newValue
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            fullWidth
                            required={required}
                            color="focus"
                            variant="standard"
                            onChange={(event) => {
                                const newValue = {
                                    name: event.target.value,
                                    id: null,
                                };
                                setValue(fieldName, newValue);
                            }}
                            sx={{
                                color: "red",
                                ".MuiInputBase-root": {
                                    padding: "5px",
                                },
                                input: {
                                    padding: "0",
                                },
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default ParserAutoComplete;
