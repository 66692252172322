import { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import getErrorMessage from "assets/data/errors.json";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUpdateCandidateById } from "components/hooks/useUpdateCandidateById";
import SaveIcon from "@mui/icons-material/Save";
import MeteredButton from "components/lib-ui/MeteredButton";
import { DeleteOutlineOutlined } from "@mui/icons-material";

export const NoteForm = ({ title, setIsDialogOpen, note, edit }) => {
    const candidate = useSelector((state) => state?.currentCandidate || {});
    const {
        updateCandidateById,
        deleteNote,
        editNote,
        isEditNoteSuccessfull,
        isDeleteNoteLoading,
        isDeleteSuccessfull,
        isLoading,
        requestSuccessful,
    } = useUpdateCandidateById();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (requestSuccessful || isEditNoteSuccessfull || isDeleteSuccessfull) {
            setIsDialogOpen(false);
        }
    }, [
        requestSuccessful,
        isEditNoteSuccessfull,
        isDeleteSuccessfull,
        setIsDialogOpen,
    ]);

    const submitForm = async (data) => {
        if (edit) {
            const endpointParams = {
                candidateId: candidate.id,
                noteId: note.id,
            };
            const payload = {
                body: data.note_body,
            };
            await editNote(endpointParams, payload);
        }
        if (!edit) {
            if (!isLoading) {
                await updateCandidateById(candidate.id, data);
            }
        }
    };
    const handleDelete = async () => {
        await deleteNote(candidate.id, note.id);
    };

    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title}</Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
                <TextField
                    {...register("note_body", {
                        required: getErrorMessage.note.required,
                    })}
                    fullWidth
                    multiline
                    minRows={4}
                    name="note_body"
                    defaultValue={edit ? note.body : ""}
                    error={Boolean(errors.note_body)}
                    helperText={
                        errors.note_body ? errors.note_body.message : ""
                    }
                    label="Type note"
                />
            </Box>

            <Stack
                direction="row"
                justifyContent={edit ? "space-between" : "flex-end"}
            >
                {edit ? (
                    <MeteredButton
                        onActionComplete={handleDelete}
                        loading={isDeleteNoteLoading}
                        buttonProps={{
                            color: "gray",
                            startIcon: <DeleteOutlineOutlined />,
                        }}
                    >
                        Delete
                    </MeteredButton>
                ) : null}

                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default NoteForm;
