import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "components/layout";
import { routes } from "./routes";
import RequireAuth from "components/Auth/RequireAuth";
import NotFound from "features/candidate/pages/NotFound";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                {routes.map((route, index) => {
                    return createRoute(route, index);
                })}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};

/**
 *
 * @param {*} route
 * @returns {JSX.Element}
 */
function createRoute(route, key) {
    let element = route.element;
    if (route.withLayout === true) {
        element = withLayout(route.element);
    }
    if (route.withAuth === true) {
        element = withAuth(element);
    }
    let path = route.path;
    return <Route key={key} path={path} element={element} />;
}

/**
 * Add layout on element
 * @param {JSX.Element} element
 * @returns
 */
function withLayout(element) {
    return <Layout>{element}</Layout>;
}

/**
 * Add Auth on element
 * @param {JSX.Element} element
 * @returns
 */
function withAuth(element) {
    return <RequireAuth>{element}</RequireAuth>;
}
export default AppRouter;
