import { useState } from "react";
import { Box } from "@mui/system";
import { TextField, Autocomplete, Chip, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useUpdateCandidateById } from "components/hooks/useUpdateCandidateById";
import { useForm } from "react-hook-form";
import GeneralUtilityService from "services/GeneralUtilityService";

/**
 * Renders an inline editable Autocomplete form field with multiple options.
 *
 * @param {string} fieldName - The name attribute of the input field. Defaults to "name".
 * @param {Array} options - The options to display in the autocomplete.
 * @param {Array} extraUpdateData - An array of additional data to be passed for updating the form. Defaults to an empty array.
 * @param {Array} [selected] - The currently selected options.
 * @param {Object} [chipAttributes] - Any additional attributes to add to the chip component.
 * @param {Object} [autocompleteOptions] - Any additional options to pass to the Autocomplete component.
 *
 * @returns {JSX.Element} - A React component that displays the inline autocomplete form field with the following features:
 * 1. Inline editing with edit button.
 * 2. Allows multiple selections.
 * 3. Displays selected options as chips or comma-separated list when not in edit mode.
 * 4. Automatically updates the candidate data upon form submission.
 * 5. Resets the temporary value to the current selected values when edit mode is canceled.
 */

export const InlineFormAutocomplete = ({
    fieldName = "name",
    extraUpdateData = [],
    options,
    selected,
    chipAttributes,
    autocompleteOptions,
}) => {
    const { handleSubmit } = useForm();
    const candidate = useSelector((state) => state.currentCandidate);
    const [value, setValue] = useState(selected ? selected : null);
    const [tempValue, setTempValue] = useState(value);

    const { updateCandidateById, isLoading } = useUpdateCandidateById();

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            editModeCancel();
        }
    };

    const editModeCancel = () => {
        setValue(value);
        setTempValue(value);
    };

    const onSubmit = () => {
        if (!isLoading) {
            setValue(tempValue);

            let modifiedExtraUpdateData = extraUpdateData;
            let updatedData = { [fieldName]: tempValue.map((each) => each.id) };

            if (modifiedExtraUpdateData.length) {
                modifiedExtraUpdateData = extraUpdateData?.map(
                    (each) => each.id
                );
                updatedData[fieldName] = [
                    ...updatedData[fieldName],
                    ...modifiedExtraUpdateData,
                ];
            }
            updateCandidateById(candidate.id, updatedData);
        }
    };
    const valueIds = value.map((item) => item.id);
    const tempValueIds = tempValue.map((item) => item.id);

    const handleDynamicSubmitForm = () => {
        if (!GeneralUtilityService.areArraysEqual(valueIds, tempValueIds)) {
            handleSubmit(onSubmit)();
        }
    };

    return (
        <Box>
            {isLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        left: "100%",
                        transform: "translateX(calc(-100% - .5rem))",
                    }}
                >
                    <CircularProgress thickness={2} color="gray" size={20} />
                </Box>
            )}
            <Box>
                <Box
                    component="form"
                    sx={{ display: "flex", position: "relative" }}
                    onKeyDown={handleKeyDown}
                >
                    <Autocomplete
                        multiple
                        fullWidth
                        options={options}
                        getOptionLabel={(option) => option.name}
                        value={tempValue || []}
                        clearIcon={false}
                        isOptionEqualToValue={(optionA, optionB) =>
                            optionA.id === optionB.id
                        }
                        onChange={(_, newValue) => setTempValue(newValue)}
                        onBlur={handleDynamicSubmitForm}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    key={option.id}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                    {...chipAttributes}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                color="focus"
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                            />
                        )}
                        {...autocompleteOptions}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default InlineFormAutocomplete;
