import Box from "@mui/material/Box";
import React from "react";
import AddCandidateForm from "features/candidate/components/candidate-form/AddCandidateForm";
import PageTitle from "components/layout/PageTitle";

export const CandidateAdd = () => {
    return (
        <Box
            sx={{
                width: 1000,
                height: "100%",
                borderRight: "1px solid lightgray",
            }}
        >
            <PageTitle title="Add A Candidate" />
            <AddCandidateForm />
        </Box>
    );
};

export default CandidateAdd;
