import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import {
    Typography,
    Autocomplete,
    Button,
    Checkbox,
    Divider,
    TextField,
    Stack,
    FormControlLabel,
} from "@mui/material";
import { addSearchFilters } from "reducers/searchFiltersDataSlice";
import { GlobalAppDataStorageService } from "infra/storage";
import removeEmptyProperties from "utils/removeEmptyProperties";
import useFetchHfrJobsAndCompanies from "components/hooks/useFetchHfrJobsAndCompanies";
import { useRecruitersAccounts } from "components/hooks/useRecruitersAccounts";
import ToggleContent from "components/lib-ui/ToggleContent";
import getErrorMessage from "assets/data/errors.json";
import RecruitmentProccessCardService from "services/RecruitmentProccessCard.service";
import useFetchCities from "components/hooks/useFetchCities";
import GeneralUtilityService from "services/GeneralUtilityService";
import { resetCitiesOptions } from "reducers/citiesSlice";
import { useTheme } from "@mui/material/styles";
import { InputLabel } from "@mui/material";

export const Filters = () => {
    const theme = useTheme();
    const {
        control,
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const workModelOptions = GlobalAppDataStorageService.getWorkingModels();
    const jobTypeOptions = GlobalAppDataStorageService.getJobTypes();
    const hardSkillsOptions = GlobalAppDataStorageService.getHardSkills();

    const cityOptions = useSelector((state) => state.citiesSlice);
    const { companyOptions, jobOptions } = useFetchHfrJobsAndCompanies();
    const rpStateOptions = GlobalAppDataStorageService.getRpStatesOptions();
    const rpStatusOptions = GlobalAppDataStorageService.getRpStatusesOptions();
    const occupationsOptions = GlobalAppDataStorageService.getOccupations();
    const jobFunctionsOptions = GlobalAppDataStorageService.getJobFunctions();

    /**
     * Transform Recruitment Process States and Statuses Filters.
     *
     * For a deeper understanding and context on how this transformation is utilized,
     * refer to the logic in `/RecruitmentProcessCard.service`.
     */
    const rpStateOptionsTransformed =
        RecruitmentProccessCardService.transformRpStatesForFilters(
            rpStateOptions
        );

    const rpStatusesOptionsTransformed =
        RecruitmentProccessCardService.transformRpStatusesForFilters(
            rpStatusOptions
        );

    useRecruitersAccounts();

    const recruitersOptions = useSelector(
        (state) => state.recruitersAccounts.data
    );

    const dispatch = useDispatch();

    const onSubmit = (filterData) => {
        const {
            currentStateStatus,
            yearOfExperienceMinimum,
            yearOfExperienceMaximum,
            salaryMinimum,
            salaryMaximum,
        } = filterData;

        if (yearOfExperienceMinimum || yearOfExperienceMaximum) {
            filterData.years_of_experience = [
                yearOfExperienceMinimum || 0, //If no min value is set, default to 0
                yearOfExperienceMaximum || 100, //If no max value is set, default to 100
            ];
        }

        if (salaryMinimum || salaryMaximum) {
            filterData.salary = [
                salaryMinimum || 0, //If no min value is set, default to 0
                salaryMaximum || 999999, //If no max value is set, default to 999999
            ];
        }

        filterData.rp_statuses = filterData.rp_statuses.flatMap(
            (item) => item.ids
        );

        filterData.rp_states = filterData.rp_states.flatMap((item) => item.ids);
        filterData.currentStateStatus = Number(currentStateStatus);
        removeEmptyProperties(filterData);
        dispatch(addSearchFilters(filterData));
    };

    const handleDynamicSubmitForm = (event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
        event.target.blur();
        if (document.activeElement) document.activeElement.blur();
    };

    const watchStatus = watch("rp_statuses");
    const watchState = watch("rp_states");

    const watchYearsOfExperienceMinimum = watch("yearOfExperienceMinimum");
    const watchYearsOfExperienceMaximum = watch("yearOfExperienceMaximum");

    const watchSalaryMinimum = watch("salaryMinimum");
    const watchSalaryMaximum = watch("salaryMaximum");

    const emptyStateOrStatus = !(
        watchState?.length > 0 || watchStatus?.length > 0
    );

    // when rp status date range becomes disabled clear the inputs of the dates
    useEffect(() => {
        setValue("rp_status_start_date", "");
        setValue("rp_status_end_date", "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emptyStateOrStatus]);

    const countryOptions = GlobalAppDataStorageService.getCountries();

    const { fetchCities, citiesFetching } = useFetchCities();

    const selectedCountry = watch("country_id");

    const hasCountry = GeneralUtilityService.isObjectEmpty(selectedCountry);

    const debouncedFetchCitiesOptions = GeneralUtilityService.debounce(
        async (countryID, q) => {
            await fetchCities(countryID, q);
        },
        1000
    );

    const handleInputChange = (event, value, reason) => {
        if (reason === "input") {
            const selectedCountryId = selectedCountry?.id;
            debouncedFetchCitiesOptions(selectedCountryId, value);
        }
    };

    return (
        <form onSubmit={handleDynamicSubmitForm}>
            <Stack
                direction="column"
                p={2}
                spacing={2}
                sx={{ position: "relative" }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="header1" component="h3">
                        Filters
                    </Typography>
                    <Button
                        sx={{
                            textTransform: "none",
                            padding: 0,
                        }}
                        variant="text"
                        onClick={(event) => {
                            reset();
                            handleDynamicSubmitForm(event);
                        }}
                    >
                        Clear all
                    </Button>
                    <Button sx={{ display: "none" }} type="submit"></Button>
                </Stack>

                <Stack spacing={3}>
                    <ToggleContent
                        title="Pool creation"
                        icon={
                            <FormatListBulletedOutlinedIcon fontSize="small" />
                        }
                    >
                        <Stack spacing={1}>
                            <Controller
                                name="skills"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="skills"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={hardSkillsOptions || []}
                                        getOptionLabel={(option) => option.name}
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option.name === value.name
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Hard skills"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="jobFunctions"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="jobFunctions"
                                        fullWidth
                                        multiple
                                        filterSelectedOptions
                                        autoComplete
                                        options={jobFunctionsOptions || []}
                                        getOptionLabel={(option) => option.name}
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option.name === value.name
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="standard"
                                                {...params}
                                                label="Job Functions"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="occupations"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="occupations"
                                        fullWidth
                                        multiple
                                        filterSelectedOptions
                                        autoComplete
                                        options={occupationsOptions || []}
                                        getOptionLabel={(option) => option.name}
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option.name === value.name
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="standard"
                                                {...params}
                                                label="Occupations"
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Stack
                                direction="column"
                                sx={{
                                    backgroundColor: theme.palette.grey[100],
                                }}
                            >
                                <Controller
                                    name="country_id"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <Autocomplete
                                            id="country_id"
                                            fullWidth
                                            limitTags={1}
                                            options={countryOptions || []}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            value={field.value || undefined}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.name === value.name}
                                            {...field}
                                            onChange={(event, newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Country"
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <Controller
                                    name="city"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <Autocomplete
                                            id="city"
                                            multiple
                                            fullWidth
                                            autoComplete
                                            filterSelectedOptions
                                            options={cityOptions}
                                            loading={citiesFetching}
                                            disabled={hasCountry}
                                            onClose={() =>
                                                dispatch(resetCitiesOptions())
                                            }
                                            onInputChange={handleInputChange}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.name === value.name}
                                            {...field}
                                            onChange={(event, newValue) => {
                                                field.onChange(newValue);
                                                handleDynamicSubmitForm(event);
                                                dispatch(resetCitiesOptions());
                                            }}
                                            renderInput={(params) => (
                                                <div>
                                                    <InputLabel htmlFor="city">
                                                        City
                                                    </InputLabel>
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        placeholder="Start typing..."
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack>
                                <Typography
                                    variant="subtitle3"
                                    fontSize="0.8rem"
                                    color="gray.medium"
                                >
                                    Years of experience range
                                </Typography>
                                <Stack direction="row" gap={2}>
                                    <Controller
                                        name="yearOfExperienceMinimum"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            validate: (value) => {
                                                const toValue =
                                                    watchYearsOfExperienceMaximum;
                                                if (
                                                    value &&
                                                    toValue &&
                                                    Number(value) >
                                                        Number(toValue)
                                                ) {
                                                    return getErrorMessage
                                                        .generic.from
                                                        .shouldBeSmaller;
                                                }
                                                return true;
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="From"
                                                variant="standard"
                                                type="number"
                                                onBlur={handleDynamicSubmitForm}
                                                error={
                                                    !!errors.yearOfExperienceMinimum
                                                }
                                                helperText={
                                                    errors
                                                        .yearOfExperienceMinimum
                                                        ?.message
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="yearOfExperienceMaximum"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            validate: (value) => {
                                                const fromValue =
                                                    watchYearsOfExperienceMinimum;
                                                if (
                                                    value &&
                                                    fromValue &&
                                                    Number(value) <
                                                        Number(fromValue)
                                                ) {
                                                    return getErrorMessage
                                                        .generic.to
                                                        .shouldBeLarger;
                                                }
                                                return true;
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="To"
                                                variant="standard"
                                                type="number"
                                                onBlur={handleDynamicSubmitForm}
                                                error={
                                                    !!errors.yearOfExperienceMaximum
                                                }
                                                helperText={
                                                    errors
                                                        .yearOfExperienceMaximum
                                                        ?.message
                                                }
                                            />
                                        )}
                                    />
                                </Stack>
                            </Stack>

                            <Controller
                                name="workingModels"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="workingModels"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={workModelOptions || []}
                                        getOptionLabel={(option) => option.name}
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option.name === value.name
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Working model"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="jobTypes"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="jobTypes"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={jobTypeOptions || []}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) =>
                                            option.name === value.name
                                        }
                                        {...field}
                                        onChange={(event, data) => {
                                            field.onChange(data);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Job type"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Stack>
                                <Typography
                                    variant="subtitle2"
                                    fontSize="0.8rem"
                                    color="gray.medium"
                                >
                                    Salary range
                                </Typography>
                                <Stack direction="row" gap={2}>
                                    <Controller
                                        name="salaryMinimum"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            validate: (value) => {
                                                const toValue =
                                                    watchSalaryMaximum;
                                                if (
                                                    value &&
                                                    toValue &&
                                                    Number(value) >
                                                        Number(toValue)
                                                ) {
                                                    return getErrorMessage
                                                        .generic.from
                                                        .shouldBeSmaller;
                                                }
                                                return true;
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="From"
                                                variant="standard"
                                                type="number"
                                                error={!!errors.salaryMinimum}
                                                onBlur={handleDynamicSubmitForm}
                                                helperText={
                                                    errors.salaryMinimum
                                                        ?.message
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="salaryMaximum"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            validate: (value) => {
                                                const fromValue =
                                                    watchSalaryMinimum;
                                                if (
                                                    value &&
                                                    fromValue &&
                                                    Number(fromValue) >=
                                                        Number(value)
                                                ) {
                                                    return getErrorMessage
                                                        .generic.to
                                                        .shouldBeLarger;
                                                }
                                                return true;
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="To"
                                                variant="standard"
                                                type="number"
                                                error={!!errors.salaryMaximum}
                                                onBlur={handleDynamicSubmitForm}
                                                helperText={
                                                    errors.salaryMaximum
                                                        ?.message
                                                }
                                            />
                                        )}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </ToggleContent>
                    <Divider />
                    <ToggleContent
                        title="Specific Candidate"
                        icon={<PersonIcon fontSize="small" />}
                    >
                        <Stack spacing={1}>
                            <TextField
                                {...register("first_name")}
                                name="first_name"
                                id="first_name"
                                label="First name"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />
                            <TextField
                                {...register("last_name")}
                                name="last_name"
                                id="last_name"
                                label="Last name"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />
                            <TextField
                                {...register("email")}
                                name="email"
                                id="email"
                                label="Email"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />
                            <TextField
                                {...register("phone_number")}
                                name="phone_number"
                                id="phone_number"
                                label="Phone number"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />

                            <TextField
                                {...register("linkedin_profile")}
                                name="linkedin_profile"
                                id="linkedin_profile"
                                label="LinkedIn"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />

                            <TextField
                                {...register("collegelink_id")}
                                name="collegelink_id"
                                id="collegelink_id"
                                label="CL profile"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />
                        </Stack>
                    </ToggleContent>
                    <Divider />
                    <ToggleContent
                        title="Recruitment Processes"
                        icon={<LinearScaleIcon fontSize="small" size="small" />}
                    >
                        <Stack spacing={1}>
                            <Controller
                                name="recruiters"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="recruiters"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={recruitersOptions || []}
                                        getOptionLabel={(option) => {
                                            const composeName =
                                                option.name || option.surname
                                                    ? `${option.name} ${option.surname}`
                                                    : null;
                                            return (
                                                `${
                                                    composeName ?? option.email
                                                }` || `Loading_${option.id}`
                                            );
                                        }}
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recruiters"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="jobs"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="jobs"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={jobOptions || []}
                                        getOptionLabel={(option) => {
                                            const jobId = option?.id;
                                            const jobName = option?.name;
                                            const companyName = option?.company
                                                ?.name
                                                ? `@${option?.company?.name}`
                                                : "";

                                            return jobName
                                                ? `${jobId} ${jobName} ${companyName}`
                                                : `Loading_${jobId}`;
                                        }}
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Jobs"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="companies"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <Autocomplete
                                        id="companies"
                                        multiple
                                        autoComplete
                                        filterSelectedOptions
                                        options={companyOptions || []}
                                        getOptionLabel={(option) =>
                                            option.name || ""
                                        }
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) =>
                                            option.name === value.name
                                        }
                                        {...field}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                            handleDynamicSubmitForm(event);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Companies"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Stack
                                direction="column"
                                sx={{
                                    py: 5,
                                }}
                            >
                                <Stack
                                    sx={{
                                        backgroundColor:
                                            theme.palette.grey[100],
                                    }}
                                >
                                    <Controller
                                        name="currentStateStatus"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(event) => {
                                                            field.onChange(
                                                                event.target
                                                                    .checked
                                                            );
                                                            handleDynamicSubmitForm(
                                                                event
                                                            );
                                                        }}
                                                    />
                                                }
                                                label="Exact matches only"
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="rp_states"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <Autocomplete
                                                id="state"
                                                multiple
                                                autoComplete
                                                filterSelectedOptions
                                                options={
                                                    rpStateOptionsTransformed
                                                }
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                value={field.value}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                {...field}
                                                onChange={(event, newValue) => {
                                                    field.onChange(newValue);
                                                    handleDynamicSubmitForm(
                                                        event
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="State"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="rp_statuses"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <Autocomplete
                                                id="status"
                                                multiple
                                                autoComplete
                                                filterSelectedOptions
                                                options={
                                                    rpStatusesOptionsTransformed
                                                }
                                                value={field.value}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                {...field}
                                                onChange={(event, newValue) => {
                                                    field.onChange(newValue);
                                                    handleDynamicSubmitForm(
                                                        event
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Status"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Stack>

                            <TextField
                                {...register("rp_status_start_date")}
                                disabled={emptyStateOrStatus}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="rp_status_start_date"
                                defaultValue={undefined}
                                label="RP Status Start Date"
                                type="date"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />

                            <TextField
                                {...register("rp_status_end_date")}
                                disabled={emptyStateOrStatus}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="rp_status_end_date"
                                defaultValue={undefined}
                                label="RP Status End Date"
                                type="date"
                                onBlur={handleDynamicSubmitForm}
                                variant="standard"
                            />
                        </Stack>
                    </ToggleContent>
                </Stack>
            </Stack>
        </form>
    );
};

export default Filters;
