import { Stack, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { InlineFieldEditor } from "components/lib-ui/parser/InlineFieldEditor";
import GeneralUtilityService from "services/GeneralUtilityService";
import ParsedDataRow from "components/lib-ui/parser/ParsedDataRow";
import { useForm } from "react-hook-form";
import { useCandidateValidation } from "components/hooks/useCandidate";
import SocialMediaURL from "services/SocialMediaURL.service";
import useNotifications from "components/hooks/useNotifications";
import { useDispatch } from "react-redux";
import {
    validateParsedCandidate,
    setEditableData,
} from "reducers/parsedProfileDataSlice";
import { emailValidator } from "validators";

const titleStyles = {
    fontSize: "1rem",
    textTransform: "uppercase",
    fontWeight: "600",
};

export const PrimaryData = ({ data }) => {
    const { generalInfo, existingUserData = {} } = data;
    const { handleSubmit, setValue } = useForm();
    const { renderErrorNotification } = useNotifications();
    const { validateCandidate, isLoading } = useCandidateValidation();

    const dispatch = useDispatch();

    const fields = [
        {
            title: "Name",
            fieldName: "firstName",
            origin: "generalInfo",
            type: "text",
        },
        {
            title: "Surname",
            fieldName: "lastName",
            origin: "generalInfo",
            type: "text",
        },
        {
            title: "Phone number",
            fieldName: "phone",
            origin: "generalInfo",
            type: "phone",
        },
        {
            title: "Email",
            fieldName: "email",
            origin: "generalInfo",
            type: "email",
        },
        {
            title: "LinkedIn url",
            fieldName: "linkedinProfile",
            origin: "generalInfo",
            type: "text",
        },
    ];

    const validateLinkedInProfile = (linkedinInput) => {
        const { isValid, message } =
            SocialMediaURL.validateLinkedinInput(linkedinInput);
        if (!isValid) {
            renderErrorNotification(message[0]);
            return false;
        }
        return SocialMediaURL.extractLinkedinId(linkedinInput);
    };

    const validateEmail = (email) => {
        const { isValid, errorMessage } = emailValidator(email);
        if (!isValid) {
            renderErrorNotification(errorMessage);
            return false;
        }
        return true;
    };

    const existingPrimaryData = !!existingUserData?.generalInfo;

    const mergeDiffDataWithExistingData = (formData, existingData) => {
        const { firstName, lastName, linkedinProfile } =
            existingData?.generalInfo || {};

        return {
            ...formData,
            firstName: formData.firstName || firstName,
            lastName: formData.lastName || lastName,
            linkedinProfile:
                formData.linkedinProfile ||
                `${SocialMediaURL.LINKEDIN_PROFILE_BASE_URL}/${linkedinProfile}`,
        };
    };

    const handleLinkedInProfile = (formData) => {
        if (!formData?.linkedinProfile) return formData;

        const processedProfile = validateLinkedInProfile(
            formData.linkedinProfile
        );

        if (!processedProfile) return null;

        return { ...formData, linkedinProfile: processedProfile };
    };

    const onSubmit = async (formData) => {
        if (existingPrimaryData) {
            formData = mergeDiffDataWithExistingData(
                formData,
                existingUserData
            );
        }

        let updatedFormData = handleLinkedInProfile(formData);

        if (!updatedFormData) return;

        if (formData.email && !validateEmail(formData.email)) return;

        const clearedFormData =
            GeneralUtilityService.clearEmptyKeys(updatedFormData);

        const checkIfShouldValidate = (isExisting) => {
            if (isExisting) {
                return true;
            } else return validateCandidate(clearedFormData);
        };

        dispatch(
            setEditableData({
                data: { generalInfo: clearedFormData },
            })
        );

        const shouldParseCandidate = await checkIfShouldValidate(
            existingPrimaryData
        );

        if (shouldParseCandidate) {
            dispatch(validateParsedCandidate());
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="1px">
                <ParsedDataRow
                    title="Primary Information"
                    titleStyles={titleStyles}
                />
                {fields.map((field) => {
                    const isLinkedinField =
                        field.fieldName === "linkedinProfile";
                    const composeLinkedin = `${
                        SocialMediaURL.LINKEDIN_PROFILE_BASE_URL
                    }/${generalInfo[field.fieldName]}`;
                    return (
                        <ParsedDataRow
                            title={field.title}
                            key={field.fieldName}
                        >
                            <Box>
                                <InlineFieldEditor
                                    fieldName={field.fieldName}
                                    defaultValue={
                                        isLinkedinField &&
                                        generalInfo[field.fieldName]
                                            ? composeLinkedin
                                            : generalInfo[field.fieldName]
                                    }
                                    type={field.type}
                                    hookFormSetValue={setValue}
                                    disabled={field.disabled}
                                    diffView={existingPrimaryData}
                                />
                            </Box>
                        </ParsedDataRow>
                    );
                })}
            </Stack>

            <Stack spacing={2} pt={6} direction="row" justifyContent="flex-end">
                <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    py={2}
                    sx={{
                        borderRadius: "100px",
                        textTransform: "none",
                        fontWeight: "400",
                        paddingLeft: 4,
                        paddingRight: 4,
                    }}
                >
                    Check user and continue
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default PrimaryData;
