import { combineReducers } from "redux";
import userValues from "reducers/userValuesSlice";
import checkboxGroupSlice from "./checkboxGroupSlice";
import searchFiltersData from "reducers/searchFiltersDataSlice";
import appLoader from "reducers/appLoaderSlice";
import candidatesList from "reducers/candidatesSlice";
import currentCandidate from "reducers/currentCandidateSlice";
import recruitersAccounts from "reducers/recruitersAccountsSlice";
import recruitmentProcesses from "reducers/recruitmentProcessesSlice";
import educations from "reducers/educationsSlice";
import experiences from "reducers/experiencesSlice";
import parsedProfileDataSlice from "reducers/parsedProfileDataSlice";
import userIdSlice from "reducers/userIdSlice";
import citiesSlice from "reducers/citiesSlice";
import sectorSlice from "reducers/sectorSlice";
import authModal from "reducers/authModal";
import hiddenInfoSlice from "./hiddenInfoSlice";

const allReducer = combineReducers({
    userValues,
    searchFiltersData,
    appLoader,
    candidatesList,
    currentCandidate,
    recruitersAccounts,
    checkboxGroupSlice,
    recruitmentProcesses,
    educations,
    experiences,
    userIdSlice,
    parsedProfileDataSlice,
    authModal,
    citiesSlice,
    sectorSlice,
    hiddenInfoSlice,
});

const rootReducer = (state, action) => {
    if (action.type === "userValues/clearStore") {
        // Reset each individual slice to its initial state
        return allReducer(undefined, action);
    }

    return allReducer(state, action);
};

export default rootReducer;
