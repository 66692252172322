import { Stack } from "@mui/material";
import DynamicFieldEditor from "./DynamicFieldEditor";
import ParserHiddenInput from "./dynamicInputs/ParserHiddenInput";
import {  useSelector } from "react-redux";
import CustomCheckbox from "../CustomCheckbox";
import { useEffect } from "react";

const FieldInteractionRow = ({
    field: { type, options, freeSolo, required },
    parsedDataValue,
    rappDataValue,
    uniqueIdentifier,
    index,
    groupName,
    formControls,
}) => {
        const checkboxGroups = useSelector(
        (state) => state.checkboxGroupSlice.checkboxGroups
    );
    const { unregister } = formControls;
    const dynamicFieldName = `dynamic_${uniqueIdentifier}`;
    const parts = uniqueIdentifier.split("_");
    const groupId = `${groupName}_${parts[1]}`;
    const isChecked = checkboxGroups[groupId]?.[uniqueIdentifier];

    const handleCheckboxChange = () => {
        if (isChecked) unregister(dynamicFieldName);
    };

    useEffect(()=>{
        if(isChecked) unregister(dynamicFieldName);
    },[dynamicFieldName, isChecked, unregister])

    return (
        <Stack direction="row" alignItems="center">
            <CustomCheckbox
                onChange={handleCheckboxChange}
                groupKey={groupId}
                checkboxKey={uniqueIdentifier}
            />

            <DynamicFieldEditor
                required={required}
                value={parsedDataValue }
                fieldName={uniqueIdentifier}
                type={type}
                disabled={!isChecked}
                options={options}
                freeSolo={freeSolo}
                index={index}
                formControls={formControls}
            />

            {!isChecked && (
                <>
                🔥
                <ParserHiddenInput
                    value={rappDataValue}
                    fieldName={dynamicFieldName}
                    disabled={isChecked}
                    formControls={formControls}
                    />
                    </>
            )}
        </Stack>
    );
};

export default FieldInteractionRow;
