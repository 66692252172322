import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleHiddenInfo } from "reducers/hiddenInfoSlice";

export const useToggleHiddenInfo = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.addEventListener("keydown", (event) => {
            if (event.ctrlKey && event.key === "6") {
                console.log("pressed");
                dispatch(toggleHiddenInfo());
            }
        });

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener("keydown");
        };
    }, [dispatch]);
};

export default useToggleHiddenInfo;
