import axios from "utils/axiosConfig";
import { urlService } from "../envs";

/**
 * Service To Support All Operations with Global App Data
 */
class GlobalAppDataStorageService {
    GLOBAL_APP_DATA_KEY = "globalAppData";

    async loadGlobalAppData() {
        if (this.areGlobalAppDataSetted() === true) {
            return;
        }
        let globalAppDataURL = urlService.GLOBAL_APP_DATA_PATH;
        let response = await axios.get(globalAppDataURL);
        this.setGlobalAppData(response.data);
    }

    setGlobalAppData(data) {
        return localStorage.setItem(
            this.GLOBAL_APP_DATA_KEY,
            JSON.stringify(data)
        );
    }

    getGlobalAppData() {
        if (!this.areGlobalAppDataSetted()) {
            throw new Error("Global App Data Are Not Loaded");
        }
        return JSON.parse(localStorage.getItem(this.GLOBAL_APP_DATA_KEY));
    }

    areGlobalAppDataSetted() {
        let data = JSON.parse(localStorage.getItem(this.GLOBAL_APP_DATA_KEY));
        if (data === null) {
            return false;
        }
        return true;
    }

    getSectors() {
        return this.getGlobalAppData().sectors;
    }
    /**
     *
     * @param {number[]} sectors array with the sectors ids
     * @returns {[]}
     */
    getSubSectors(sectors = []) {
        if (sectors.length === 0) {
            return this.getSectors().subsectors;
        }
        return this.getSubSectors().filter((subSector) =>
            sectors.includes(subSector.parent_sector.id)
        );
    }

    getParentSectors() {
        const primarySectors = this.getGlobalAppData().sectors.primary;
        return primarySectors
            ? primarySectors.map((item) => ({ id: item.id, name: item.name }))
            : [];
    }

    getCountries() {
        return this.getGlobalAppData().countries;
    }
    getCities() {
        return this.getGlobalAppData().cities;
    }

    getSkills() {
        return this.getGlobalAppData().skills;
    }

    /**
     * Retrieves the list of hard skills from the global app data
     * @returns {Array} - An array of hard skill objects
     */
    getHardSkills() {
        return this.getSkills().hard;
    }

    getSoftSkills() {
        return this.getSkills().soft;
    }

    /**
     * Retrieves the list of means of contact from the global app data
     * @returns {Array} - An array of means of contact objects
     */
    getMeansOfContact() {
        return this.getGlobalAppData().means_of_contact;
    }

    /**
     * Retrieves the list of attitude optionsfrom the global app data
     * @returns {Array} - An array of attitude objects
     */
    getAttitudes() {
        return this.getGlobalAppData().attitudes;
    }

    /**
     * Retrieves the list of working models from the global app data
     * @returns {Array} - An array of working model objects
     */
    getWorkingModels() {
        return this.getGlobalAppData().working_models;
    }

    /**
     * Retrieves the list of job types from the global app data
     * @returns {Array} - An array of job type objects
     */
    getJobTypes() {
        return this.getGlobalAppData().job_types;
    }

    /**
     * Retrieves the list of mobilities from the global app data
     * @returns {Array} - An array of mobility objects
     */
    getMobilities() {
        return this.getGlobalAppData().mobilities;
    }

    /**
     * Retrieves the list of company sizes from the global app data
     * @returns {Array} - An array of company size objects
     */
    getCompanySizes() {
        return this.getGlobalAppData().company_sizes;
    }

    /**
     * Retrieves the list of vehicles from the global app data
     * @returns {Array} - An array of vehicle objects
     */
    getVehicles() {
        return this.getGlobalAppData().vehicles;
    }
    /**
     * Retrieves the list of genders from the global app data
     * @returns {Array} - An array of gender objects
     */
    getGenders() {
        return this.getGlobalAppData().genders;
    }
    /**
     * Retrieves the list of genders from the global app data
     * @returns {Array} - An array of gender objects
     */
    getLanguages() {
        return this.getGlobalAppData().languages;
    }
    /**
     * Retrieves the list of genders from the global app data
     * @returns {Array} - An array of gender objects
     */
    getLanguageLevels() {
        return this.getGlobalAppData().language_levels;
    }

    /**
     * Retrieves the list of motivations from the global app data
     * @returns {Array} - An array of motivation objects
     */
    getMotivations() {
        return this.getGlobalAppData().motivations;
    }
    /**
     * Retrieves the list of seniority options from the global app data
     * @returns {Array} - An array of seniority objects
     */
    getSeniority() {
        return this.getGlobalAppData().seniority_levels;
    }
    getSourceOptions() {
        return this.getGlobalAppData().sources;
    }
    getResponsibilitiesOptions() {
        return this.getGlobalAppData().responsibilities;
    }
    getCompanysizesOptions() {
        return this.getGlobalAppData().company_sizes;
    }
    getAttitudesOptions() {
        return this.getGlobalAppData().attitudes;
    }
    getRpStatusesOptions() {
        return this.getGlobalAppData().rp_statuses;
    }
    getRpStatesOptions() {
        return this.getGlobalAppData().rp_states;
    }
    getRpJustificationsOptions() {
        return this.getGlobalAppData().rp_justifications;
    }
    /**
     * Retrieves the list of recruitment process note categories
     * @returns {Array} - An array of recruitment process note category objects
     */
    getRpNoteCategories() {
        return this.getGlobalAppData().rp_note_categories;
    }
    /**
     * Retrieves the list of compensation preferences
     * @returns {Array} - An array of compensation preferences objects
     */
    getCompensationPreferences() {
        return this.getGlobalAppData().compensation_preferences;
    }
    /**
     * Retrieves the list of recruitment process note categories
     * @returns {Array} - An array of education level category objects
     */
    getEducationLevels() {
        return this.getGlobalAppData().education_levels;
    }
    getCompaniesOptions() {
        return this.getGlobalAppData().companies;
    }
    getCompanyTypes() {
        return this.getGlobalAppData().company_types;
    }
    getCompanyIndustries() {
        return this.getGlobalAppData().company_industries;
    }
    getJobChangeReasons() {
        return this.getGlobalAppData().job_change_reasons;
    }
    /**
     *  Retrieves the list of contact frequency from the global app data
     * @returns {Array} - An array of  contact frequency objects
     */
    getContactFrequencies() {
        return this.getGlobalAppData().contact_frequencies;
    }

    /**
     *  Retrieves the list of ergani specialization codes from the global app data
     * @returns {Array} - An array of  contact ergani specialization codes objects
     */
    getErganiSpecializationCodes() {
        return this.getGlobalAppData().ergani_specialization_codes;
    }

    /**
     *  Retrieves the list of ergani education levels from the global app data
     * @returns {Array} - An array of ergani education levels
     */
    getErganiEducationLevels() {
        return this.getGlobalAppData().ergani_education_levels;
    }

    /**
     *  Returns a hardcoded list of unemployment options
     * @returns {Array} - An array of unemployment options objects
     */
    getUnemployedOptions() {
        return [
            {
                id: 0,
                name: "No",
            },
            {
                id: 1,
                name: "Yes",
            },
        ];
    }

    getIndustries() {
        return this.getGlobalAppData().industries;
    }

    getCallingCodes() {
        const codes = this.getGlobalAppData().calling_codes;

        return codes
            ? codes.map((item) => ({
                  id: item.id,
                  name: `${item.country.name} +${item.calling_code}`,
              }))
            : [];
    }

    getAcademicFields() {
        return this.getGlobalAppData().academic_fields;
    }
    getJobFunctions() {
        return this.getGlobalAppData().job_functions;
    }
    getOccupations() {
        return this.getGlobalAppData().occupations;
    }
}

const service = new GlobalAppDataStorageService();
export default service;
