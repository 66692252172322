import React, { memo } from "react";
import { Box, Chip, Stack } from "@mui/material";
import { format, parse } from "date-fns";

const RenderChips = memo(({ chips, color = "primary", variant = "filled" }) => {
    if (!Array.isArray(chips) || chips.length === 0) return null;

    return (
        <Stack direction="row" flexWrap="wrap" gap={1}>
            {chips.map((chip) => (
                <Chip
                    key={chip.id || chip.name}
                    color={color}
                    label={chip.name}
                    variant={variant}
                />
            ))}
        </Stack>
    );
});

const formatDate = (value) => {
    const originalFormat = "yyyy-MM-dd";
    const desiredFormat = "dd-MMM-yyyy";
    const dateObject = parse(value, originalFormat, new Date());
    return format(dateObject, desiredFormat);
};

export const ValueDisplay = ({ value, type }) => {
    const renderValue = () => {
        switch (type) {
            case "date":
                return formatDate(value);
            case "autocomplete":
            case "options":
                return Array.isArray(value)
                    ? value.map((each) => each.name).join(", ")
                    : value?.name || "";
            case "sectors":
            case "subsectors":
            case "skills":
                const colorMap = {
                    subsectors: "greenCyan",
                    skills: "skillChipColor",
                };

                const color = colorMap[type] || "primary";

                return <RenderChips chips={value} color={color} />;
            case "year":
            case "text":
            case "textarea":
            default:
                return value?.name || value || "";
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box className="rappDataStyles"> {renderValue()}</Box>
        </Box>
    );
};

export default ValueDisplay;
