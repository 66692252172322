import { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRecruitmentProcess } from "components/hooks/useRecruitmentProcess";
import Utils from "services/GeneralUtilityService";
import SaveIcon from "@mui/icons-material/Save";
import HorizontalFlex from "components/lib-ui/HorizontalFlex";
import MenuItem from "@mui/material/MenuItem";
import { GlobalAppDataStorageService } from "infra/storage";
import ReProService from "services/RecruitmentProccessCard.service";

export const EditState = ({ title, setIsDialogOpen, stepData }) => {
    const candidate = useSelector((state) => state.currentCandidate);
    const recruitmentProccessId = stepData.allSteps.recruitmentProcessId;
    const { updateRecruitmentProcessState, isLoading, requestSuccessful } =
        useRecruitmentProcess();
    const stateOptions = GlobalAppDataStorageService.getRpStatesOptions();

    const statusOptions = GlobalAppDataStorageService.getRpStatusesOptions();

    const recruitmentProccessCurrentId =
        stepData?.allSteps?.currentRecruitmentProcessId;

    const filterStatusOptions = ReProService.filterOptionsByCategoryId(
        statusOptions,
        recruitmentProccessCurrentId
    );

    const justOptions =
        GlobalAppDataStorageService.getRpJustificationsOptions();

    const filterJustOptions = ReProService.filterOptionsByCategoryId(
        justOptions,
        recruitmentProccessCurrentId
    );

    const { handleSubmit, register, watch } = useForm();

    //TODO: Refactor this logic to a more centralized solution
    const statuses = {
        DROPPED: { INTERNAL_GENERAL: 10, EXTERNAL: 11, INTERNAL_SPECIFIC: 12 },
        REJECTED: { INTERNAL_GENERAL: 7, EXTERNAL: 8, INTERNAL_SPECIFIC: 9 },
        SHORTLISTED: 13,
    };

    const watchStatus = watch("rpStepStatusId");

    const isStatusRejected = [
        statuses.REJECTED.INTERNAL_GENERAL,
        statuses.REJECTED.INTERNAL_SPECIFIC,
        statuses.REJECTED.EXTERNAL,
    ].includes(watchStatus);

    const isStatusShortlisted = statuses.SHORTLISTED === watchStatus;

    const isStatusDropped = [
        statuses.DROPPED.INTERNAL_GENERAL,
        statuses.DROPPED.INTERNAL_SPECIFIC,
        statuses.DROPPED.EXTERNAL,
    ].includes(watchStatus);

    useEffect(() => {
        if (requestSuccessful) {
            setIsDialogOpen(false);
        }
    }, [requestSuccessful, setIsDialogOpen]);

    const submitForm = async (data) => {
        if (!isLoading) {
            const endpointParams = {
                candidateId: candidate.id,
                recruitmentProccessId: recruitmentProccessId,
                flowId: stepData?.allSteps?.id,
                rpStepStateId: Utils.getLastItem(
                    stepData?.allSteps?.rpFlowSteps
                )?.id,
            };
            data.score = data.score && Number(data.score).toFixed(1);

            await updateRecruitmentProcessState(endpointParams, data);
        }
    };
    return (
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
            <Box mb={2}>
                <Typography variant="header2">{title}</Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
                <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                    {/* Step State */}
                    <TextField
                        select
                        fullWidth
                        disabled
                        defaultValue={stepData?.rpStepState?.id || ""}
                        name="state"
                        {...register("state", {})}
                        label="State"
                    >
                        {stateOptions.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                                {option.state.name || ""}
                            </MenuItem>
                        ))}
                    </TextField>
                    {/* Step Status */}
                    <TextField
                        select
                        fullWidth
                        name="rpStepStatusId"
                        required
                        defaultValue={stepData?.rpStepStatuses?.id || ""}
                        {...register("rpStepStatusId", {})}
                        label="Status"
                    >
                        {filterStatusOptions.map((option) => {
                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.status.name || ""}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </HorizontalFlex>
                {isStatusShortlisted && (
                    <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                        <Stack sx={{ width: "100%" }}>
                            <Stack direction="row" gap={2}>
                                <TextField
                                    sx={{ minWidth: "150px" }}
                                    label="Score"
                                    required
                                    defaultValue={stepData?.score || ""}
                                    {...register("score", {})}
                                    inputProps={{
                                        step: 0.5,
                                        min: 0,
                                        max: 10,
                                        type: "number",
                                        "aria-labelledby": "input-slider",
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </HorizontalFlex>
                )}
                {isStatusShortlisted && (
                    <HorizontalFlex sx={{ marginBottom: "1rem" }}>
                        <TextField
                            {...register("report")}
                            required
                            fullWidth
                            multiline
                            minRows={4}
                            name="report"
                            defaultValue={stepData.allSteps.report}
                            label="Report"
                        />
                    </HorizontalFlex>
                )}
                {isStatusDropped || isStatusRejected ? (
                    <HorizontalFlex>
                        <TextField
                            select
                            fullWidth
                            required
                            name="rpStepJustificationId"
                            defaultValue={candidate?.status || ""}
                            {...register("rpStepJustificationId", {})}
                            label="Justification"
                        >
                            {filterJustOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.justification.name || ""}
                                </MenuItem>
                            ))}
                        </TextField>
                    </HorizontalFlex>
                ) : null}
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    color="gray"
                    variant="text"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Cancel
                </Button>

                <LoadingButton
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Save changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default EditState;
