import Box from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MainNavigationItem from "./MainNavigationItem";

export default function MainNavigation({ isSidebarOpen }) {
    return (
        <Box sx={{ padding: "10px", paddingTop: "30px" }}>
            <MenuList>
                <MainNavigationItem
                    to="/"
                    text="HFR Sourced Candidates"
                    icon={<LocalFireDepartmentOutlinedIcon />}
                    activeIcon={<LocalFireDepartmentIcon />}
                    isSidebarOpen={isSidebarOpen}
                />
            </MenuList>
        </Box>
    );
}
