import { useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import DialogBox from "components/lib-ui/DialogBox";
import BlankState from "../BlankState";
import AddIcon from "@mui/icons-material/Add";
import AddNewRecuitmentProcess from "../../candidate-form/recruitment-processes/AddNewRecuitmentProcess";
import RecruitmentProcessCard from "../RecruitmentProcessCard";
import { useRecruitersAccounts } from "components/hooks/useRecruitersAccounts";
import { useSelector } from "react-redux";
import ExpandableButton from "components/lib-ui/ExpandableButton";

export const RecruitmentProcesses = () => {
    useRecruitersAccounts();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { data: recruitmentProcesses, loading } = useSelector(
        (state) => state.recruitmentProcesses
    );

    return (
        <Box
            sx={{
                maxWidth: "1200px",
            }}
        >
            {/* If recruitmentProcesses is not available yet, return a loading
            spinner */}
            {loading && (
                <div data-testid="recruitmentProcess/loading">
                    <CircularProgress />
                </div>
            )}
            {/* If there are no recruitmentProcesses, return the BlankState */}
            {recruitmentProcesses?.length === 0 && !loading && (
                <Box>
                    <BlankState
                        text="No active recruitment processes"
                        showButton={true}
                        dataTestId="recruitmentProcess/create"
                        buttonText="Create recruitment process"
                        onClick={() => {
                            setIsDialogOpen(true);
                        }}
                    />
                </Box>
            )}

            <DialogBox
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                title="Create recruitment process"
                floatCancel={true}
                showActionButtons={false}
            >
                <AddNewRecuitmentProcess setIsDialogOpen={setIsDialogOpen} />
            </DialogBox>

            {!loading && recruitmentProcesses?.length > 0 && (
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 3,
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="header2" component="h3">
                            Active
                        </Typography>

                        {recruitmentProcesses.map(
                            (recruitmentProcessData, index) => (
                                <RecruitmentProcessCard
                                    key={index}
                                    recruitmentProcessData={
                                        recruitmentProcessData
                                    }
                                />
                            )
                        )}

                        <Box
                            sx={{
                                position: "fixed",
                                right: "30px",
                                bottom: "30px",
                            }}
                        >
                            {!isDialogOpen && (
                                <ExpandableButton
                                    dataTestId="recruitmentProcess/create"
                                    text="Create recruitment process"
                                    icon={AddIcon}
                                    onClick={() => {
                                        setIsDialogOpen(true);
                                    }}
                                />
                            )}
                        </Box>
                    </Box>{" "}
                </Box>
            )}
        </Box>
    );
};

export default RecruitmentProcesses;
