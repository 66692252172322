import { useState } from "react";
import PageTitle from "components/layout/PageTitle";
import { Paper, Stack, Collapse } from "@mui/material";
import DataTable from "features/candidate/components/candidate-listing/DataTable";
import Filters from "features/candidate/components/candidate-listing/Filters";

export const CandidatesList = () => {
    const [filterVisibility, setFilterVisibility] = useState(true);
    return (
        <Stack direction="column">
            <PageTitle title="Search for candidates" />
            <Stack
                px="20px"
                pb="20px"
                spacing={filterVisibility && 2}
                direction="row"
                className="listing-table"
            >
                <Collapse orientation="horizontal" in={filterVisibility}>
                    <Paper
                        sx={{
                            maxWidth: "350px",
                            borderRadius: "10px",
                            height: "fit-content",
                        }}
                    >
                        <Filters />
                    </Paper>
                </Collapse>
                <Paper
                    sx={{
                        padding: "20px 0",
                        width: "100%",
                        borderRadius: "10px ",
                        height: "fit-content",
                    }}
                >
                    <DataTable setFilterVisibility={setFilterVisibility} />
                </Paper>
            </Stack>
        </Stack>
    );
};

export default CandidatesList;
